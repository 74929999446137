import React from "react";
import tw, { styled } from "twin.macro";
import { format, fromUnixTime, addYears } from "date-fns";
import { PieChart, Pie, Cell } from "recharts";
import * as S from "../styles/versus-graph-styles";

import formatter from "../utils/formatter";

import offsetIcon from "../assets/icons/versus/versusOffset.svg";
import thumbIcon from "../assets/icons/thumbIcon.svg";
import utilityLine from "../assets/icons/versus/utilityLine.svg";
import LoanChart from "./loanChart";
import CashChart from "./cashChart";
import Incentives from "./versusIncentives";

import VersusSlider from "./versusSlider";

class VersusGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    this.refreshData();

    const sliderMarks = [
      {
        value: 0,
        label: "0",
      },
      {
        value: 1,
        label: "",
      },
      {
        value: 2,
        label: "",
      },
      {
        value: 3,
        label: "",
      },
      {
        value: 4,
        label: "",
      },
      {
        value: 5,
        label: "5",
      },
      {
        value: 10,
        label: "10",
      },
      {
        value: 15,
        label: "15",
      },
      {
        value: 20,
        label: "20",
      },
      {
        value: 25,
        label: "25",
      },
    ];

    if (this.props.fullSavingsTerm == 30) {
      sliderMarks.push({
        value: 30,
        label: "30",
      });
    }
    this.sliderMarks = sliderMarks;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.shouldRender != this.props.shouldRender) {
      this.refreshData();
    }
  }

  refreshData() {
    const data = {
      before: this.props.data.before,
      after: this.props.data.after,
      cumulativeCost: this.props.data.cumulativeCost,
      cumulativeSavings: this.props.data.cumulativeSavings,
      cumulativeUtility: this.props.data.cumulativeUtility,
      yearlyDifference: this.props.data.yearlyDifference,
      loanOnly: this.props.data.loanOnly,
    };

    const graphData = data.before.map((d, i) => ({
      before: d,
      loanOnly: data.loanOnly[i],
      index: i,
    }));
    // Add after IF the property applicable
    if (data.after.length) {
      graphData.forEach((g, i) => {
        g.after = data.after[i];
      });
    }
    if (this.props.proposal.fundingPartnerName == "Cash" || this.props.proposal.isLease) {
      graphData.forEach((g, i) => {
        g.cumulativeCost = data.cumulativeCost[i];
        g.cumulativeSavings = data.cumulativeSavings[i];
        g.cumulativeUtility = data.cumulativeUtility[i];
      });
    }

    const dateCreated = fromUnixTime(this.props.proposal.dateCreated / 1000);

    this.setState({
      data,
      graphData,
      dateCreated,
      graphIndex: this.props.graphIndex,
    });
  }

  Thumb(props) {
    return (
      <div {...props}>
        <S.ThumbDiv>
          <p>{props["aria-valuenow"]}</p>
          <img src={thumbIcon} />
        </S.ThumbDiv>
      </div>
    );
  }

  render() {
    const { proposal, fullSavingsTerm } = this.props;
    const {
      offset,
      fundingPartnerName,
      isLease = false,
    } = proposal;

    const calculatedOffset = offset * 100;
    const circleData = [{ id: "1", name: "L1", value: calculatedOffset }];
    if (calculatedOffset < 100) {
      circleData.push({ id: "2", name: "L2", value: 100 - calculatedOffset });
    }

    const outerCircleData = [
      { id: "3", name: "L3", value: calculatedOffset - 100 },
    ];
    if (calculatedOffset < 200 && calculatedOffset > 100) {
      outerCircleData.push({
        id: "4",
        name: "L4",
        value: 200 - calculatedOffset,
      });
    }

    const isCash = fundingPartnerName == "Cash";
    const circleSize = 130;
    return this.state.graphData ? (
      <>
        <S.UtilitySidebar>
          <div className="heading">
            <p>Monthly Payment</p>
            {!isCash && !isLease && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36.33"
                height="36.33"
                viewBox="0 0 36.33 36.33"
              >
                <path
                  id="Path_5739"
                  data-name="Path 5739"
                  d="M-2.43-2.43,18.618-7.071,13.977,13.977-7.071,18.618Z"
                  transform="translate(10 18.165) rotate(-45)"
                  fill="#ff3636"
                />
              </svg>
            )}
          </div>
          <p className="cost big">
            $ {formatter(this.state.graphData[this.props.graphIndex].before, 0)}
          </p>
          <img
            src={`https://cdn.genability.com/lses/${this.props.proposal.genabilityLseID}.png`}
          />
          <p>Your Outgoings</p>
          <p className="cost">
            ${" "}
            {formatter(
              this.props.graphIndex
                ? this.state.data.cumulativeCost[this.props.graphIndex]
                : this.state.graphData[this.props.graphIndex].before * 12,
              0
            )}
          </p>
        </S.UtilitySidebar>
        <S.GraphDiv>
          <div style={{ width: "840px" }} id="versusGraph">
            {isCash || isLease ? (
              <CashChart
                graphData={this.state.graphData}
                graphIndex={this.props.graphIndex}
                isLease={isLease}
              />
            ) : (
              <LoanChart
                graphData={this.state.graphData}
                graphIndex={this.props.graphIndex}
                yearlyDifference={this.state.data.yearlyDifference}
                fullSavingsTerm={fullSavingsTerm}
              />
            )}
            <div style={{ paddingRight: "100px" }}>
              <VersusSlider
                aria-label="ios slider"
                value={this.props.graphIndex}
                max={this.props.fullSavingsTerm}
                marks={this.sliderMarks}
                valueLabelDisplay="on"
                onChange={(_, v) => this.props.updateGraphIndexFn(v)}
                ThumbComponent={this.Thumb}
                style={{
                  markLabel: {
                    position: "absolute",
                  },
                }}
              />
              <S.YearLabels>
                <div className="first">
                  {format(this.state.dateCreated, "yyyy")}
                </div>
                {[1, 2, 3, 4, 5].map((i) => (
                  <div key={`yearLabel-${i}`}>
                    {format(addYears(this.state.dateCreated, i), "yy")}
                  </div>
                ))}
                {(fullSavingsTerm == 30
                  ? [10, 15, 20, 25, 30]
                  : [10, 15, 20, 25]
                ).map((i) => (
                  <React.Fragment key={`yearLabel-${i}`}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                      {format(addYears(this.state.dateCreated, i), "yyyy")}
                    </div>
                  </React.Fragment>
                ))}
              </S.YearLabels>
              <S.Label>Years</S.Label>
            </div>
          </div>
          {!isCash && !isLease ? (
            <Incentives
              proposal={this.props.proposal}
              updateProposalFn={this.props.updateProposalFn}
            />
          ) : (
            <></>
          )}
        </S.GraphDiv>

        <S.SolarSidebar>
          <div className="heading">
            {!isCash && !isLease && (
              <svg
                id="Component_26_1"
                data-name="Component 26 – 1"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
              >
                <rect
                  id="Rectangle_4112"
                  data-name="Rectangle 4112"
                  width="19"
                  height="19"
                  rx="9.5"
                  fill="#97d33a"
                />
                <g
                  id="Rectangle_4113"
                  data-name="Rectangle 4113"
                  transform="translate(6 6)"
                  fill="red"
                  stroke="#fff"
                  strokeWidth="1"
                >
                  {calculatedOffset < 100 && (
                    <>
                      <rect width="7" height="7" rx="3.5" stroke="none" />
                      <rect
                        x="-0.5"
                        y="-0.5"
                        width="8"
                        height="8"
                        rx="4"
                        fill="none"
                      />
                    </>
                  )}
                </g>
              </svg>
            )}
            <p>Monthly Payment</p>
          </div>
          <p className={`cost big ${isCash ? "cash" : ""}`}>
            ${" "}
            {formatter(
              isCash ? 0 : this.state.graphData[this.props.graphIndex].loanOnly,
              0
            )}
          </p>
          <div className="offset">
            <div className="vertical-line" />
            <PieChart width={circleSize} height={circleSize}>
              <Pie
                data={circleData}
                dataKey="value"
                innerRadius="82%"
                outerRadius="88%"
                fill="#eee"
                startAngle={90}
                endAngle={-270}
                paddingAngle={0}
                blendStroke
              >
                <Cell key="l2" fill="#97D33A" />
              </Pie>
              {calculatedOffset > 100 ? (
                <Pie
                  data={outerCircleData}
                  dataKey="value"
                  innerRadius="94%"
                  outerRadius="100%"
                  fill="#F4F4F4"
                  startAngle={90}
                  endAngle={-270}
                  paddingAngle={0}
                  blendStroke
                >
                  <Cell key="l3" fill="#97D33A" />
                </Pie>
              ) : (
                <></>
              )}
            </PieChart>

            <div
              className="offsetContent"
              style={{
                width: circleSize + "px",
                height: circleSize + "px",
              }}
            >
              <img src={offsetIcon} />
              <h2>{formatter(calculatedOffset, 0)}%</h2>
              <p>Solar Offset</p>
            </div>
            {calculatedOffset < 100 && (
              <div className="utility-cost">
                <img src={utilityLine} />
                <p>
                  ${" "}
                  {formatter(
                    this.props.graphIndex
                      ? this.state.data.after[this.props.graphIndex] -
                      this.state.data.loanOnly[this.props.graphIndex]
                      : 0,
                    0
                  )}
                </p>
              </div>
            )}
          </div>
          <p>
            {this.props.proposal.fundingPartnerName == "Cash" &&
              this.state.data.cumulativeSavings[this.props.graphIndex] < 0
              ? " Your Investment"
              : "Your Savings"}
          </p>
          <p
            className={
              "cost" +
              (this.props.proposal.fundingPartnerName == "Cash" &&
                this.state.data.cumulativeSavings[this.props.graphIndex] < 0
                ? " negative"
                : "")
            }
          >
            <span className="bubble" />${" "}
            {formatter(
              this.props.graphIndex ||
                this.props.proposal.fundingPartnerName == "Cash"
                ? this.state.data.cumulativeSavings[this.props.graphIndex]
                : 0,
              0
            )}
          </p>
          {calculatedOffset < 100 && (
            <>
              <p className="cost utility">
                <span className="bracket">(</span>
                <span className="minus"></span>
                <span className="bracket">)</span>
                <span className="bubble" />${" "}
                {formatter(
                  this.props.graphIndex
                    ? this.state.data.cumulativeUtility[this.props.graphIndex]
                    : 0,
                  0
                )}
              </p>
            </>
          )}

          <div className="average">
            <p style={{ textAlign: "left" }}>Average Annual Savings</p>
            <p className="price">
              {this.props.graphIndex &&
                this.state.data &&
                this.state.data.cumulativeSavings
                ? "$" +
                formatter(
                  this.state.data.cumulativeSavings[this.props.graphIndex] /
                  (this.props.graphIndex !== 0
                    ? this.props.graphIndex
                    : 25),
                  0
                )
                : this.props.data
                  ? "$" + formatter(this.props.data.cumulativeSavings[25] / 25, 0)
                  : ""}
            </p>
          </div>
        </S.SolarSidebar>
      </>
    ) : (
      <></>
    );
  }
}

export default VersusGraph;
