import axios from "axios";

const url = "https://integrations.sunobiapp.com/api/customer/proposal";
//const url = "http://localhost:9090/api/customer/proposal"
const clist = {
    //dateCreated: 1, we add the original dateCreated preserving unix stamp
    proposalRecordID: 3,
    leadName: 7,
    leadFullAddress: 203,
    addressZip: 11,
    installerRecordID: 305,
    totalAnnualEnergyConsumption: 25,
    totalAnnualEnergyConsumptionModified: 1367,
    totalAnnualEnergyCost: 26,
    fundingPartnerName: 139,
    summaryTaxCredit: 147,
    paymentInitial: 150,
    paymentAdjusted: 151,
    paymentFlex: 1082,
    offset: 278,
    estimatedProductionOverride: 279,
    systemSizeKW: 280,
    financeProgramTerm: 406,
    financeProgramRate: 407,
    genabilityLseID: 878,
    manualBillCalculation: 883,
    utilityProviderName: 894,
    utilityTariffName: 895,
    utilityEscalator: 1031,
    packagePrice: 146,
    totalNumberOfPanels: 66,
    jobCreated: 461, // Added
    kotaHIA: 261,
    loanpalFormURL: 427,
    signingCompletedFinanceDocs: 1128,
    stateRebate: 1216,
    utilityRebate: 1214,
    totalRebates: 1231,
    applyFederalRebate: 1232, 
    applyStateRebate: 1215, 
    applyUtilityRebate: 1213,
    paymentBest: 1284, // Missing 
    initialTerm: 1536,
    utilityMode: 1186,
    utilityData: 1187,
    numberOfSolarArrays: 1714,
    isLease: 2097,
    leaseEscalator: 2098,
    batteryRecordID: 1733
};

const mapObject = (fields) => {
    const mappedProposal = {};
    for (let key of Object.keys(clist)) {
        if (fields.hasOwnProperty(clist[key])) {
            mappedProposal[key] = fields[clist[key]].value;
        }
    }
    return mappedProposal;
};

const getClistArray = () => {
    const cListArray = [];
    for (let key of Object.keys(clist)) {
        cListArray.push(clist[key]);
    }
    return cListArray;
};

const getProposalData = async (body) => {
    body.clist = getClistArray();
    const response = await axios.post(url, body);
    if (!response.data.hasError) {
        const {
            fields,
            documents,
            finance = {},
            inverter = {},
            panel = {},
            battery = {},
            customisations = {},
            numberOfPanelGroupsFromDesign = 0,
            summaryRegion = 0,
            yearlyBill = 0
        } = response.data;
        const proposal = mapObject(fields);

        //!!!!!  Border testing settings !!!!!//

        // customisations.salesOrganisationLogoBorder = true;
        // customisations.salesOrganisationLogoBorderColor = "#000000";
        // customisations.installerLogoBorder = true;
        // customisations.installerLogoBorderColor = "#FFFFFF";
        // customisations.installerLogoScale = "1";


        return {
            proposal,
            documents,
            finance,
            inverter,
            battery,
            panel,
            customisations,
            numberOfPanelGroupsFromDesign,
            summaryRegion,
            yearlyBill
        };
    } else {
        return {
            proposal: {},
            documents: [],
        };
    }
};

const putProposalData = async (body) => {
    body.clist = getClistArray();
    const response = await axios.put(url, body);
    if (!response.data.hasError) {
        const { fields, documents } = response.data;
        const proposal = mapObject(fields);
        return {
            proposal,
            documents,
        };
    } else {
        return {
            proposal: {},
            documents: [],
        };
    }
};

export { getProposalData, putProposalData };