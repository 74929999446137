import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import formatter from "../utils/formatter";

import rebatesBackground from "../assets/rebatesBackground-update.jpg";
const Rebates = ({ proposal, isCash }) => {
  const {
    summaryTaxCredit: taxCredit,
    stateRebate = 0,
    utilityRebate = 0,
  } = proposal;

  const Container = styled.div`
    ${tw`flex relative flex-col items-center flex-col justify-between px-8 bg-center pt-24`}
    ${isCash ? tw`pb-24` : null}
  width: 1280px;
    max-width: 1500px;
  `;

  const Background = styled.div`
    ${tw`w-screen flex justify-center`}

    background-size: 1719px 800px;
    background-position: 50% 50px;
    background-image: url(${rebatesBackground});
    background-repeat: no-repeat;
    height: 900px;
    margin-bottom: 50px;
  `;

  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      <Background>
        <Container>
          <div>
            <Title>Solar Incentives</Title>
            <SubTitle>
              Federal, State, and Utility benefits combined
              <br />
              give you your total Solar Tax incentives
            </SubTitle>
          </div>

          <Federal>
            <p>$ {formatter(taxCredit)}</p>
          </Federal>
          <State>
            <p>$ {formatter(stateRebate)}</p>
          </State>
          <Utility>
            <p>$ {formatter(utilityRebate)}</p>
          </Utility>

          <Total>
            <p>$ {formatter(taxCredit + stateRebate + utilityRebate)}</p>
          </Total>
        </Container>
      </Background>
    </div>
  );
};

export default Rebates;

const Title = tw.h1`
    text-xl uppercase text-center text-sunobi-dark font-light tracking-wider pb-4
`;
const SubTitle = tw.h2`
    text-base text-center font-thin tracking-wider text-sunobi-dark opacity-75
`;
const Federal = styled.div`
  ${tw`absolute flex justify-center items-center`}
  width: 17.5%;
  height: 6.5%;
  right: 13.63%;
  top: 43.5%;

  p {
    ${tw`text-3xl uppercase text-center text-white font-light tracking-wider m-0`}
  }
`;
const State = styled.div`
  ${tw`absolute flex justify-center items-center`}

  width: 9%;
  height: 5%;
  left: 52.66%;
  top: 31.5%;
  p {
    ${tw`text-xl uppercase text-center text-white font-light tracking-wider m-0`}
  }
`;
const Utility = styled.div`
  ${tw`absolute flex justify-center items-center`}
  width: 9%;
  height: 5%;
  left: 37.4%;
  top: 31.5%;
  p {
    ${tw`text-xl uppercase text-center text-white font-light tracking-wider m-0`}
  }
`;

const Total = styled.div`
  ${tw`absolute flex justify-center items-center`}

  width: 17.66%;
  height: 7.4%;
  left: 12.5%;
  top: 17.3%;
  p {
    ${tw`text-3xl uppercase text-center text-sunobi-dark font-light tracking-wider m-0`}
  }
`;
