import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import formatter from "../utils/formatter";
import * as S from "../styles/enviro-video";

import fossilIcon from "../assets/vid/fossil-icon.svg";
import solarIcon from "../assets/vid/solar-icon.svg";
import infoIcon from "../assets/vid/info-icon.svg";
import infoCloseIcon from "../assets/vid/info-close-icon.svg";
import markerIcon from "../assets/vid/marker-icon.svg";

import fossil1 from "../assets/enviro/fossil/1-pollution.png";
import fossil2 from "../assets/enviro/fossil/2-fires.png";
import fossil3 from "../assets/enviro/fossil/3-huge-spending.png";
import fossil4 from "../assets/enviro/fossil/4-rising-costs.png";

import solar1 from "../assets/enviro/solar/1.png";
import solar2 from "../assets/enviro/solar/2.png";
import solar3 from "../assets/enviro/solar/3.png";
import solar4 from "../assets/enviro/solar/4.png";
import solar5 from "../assets/enviro/solar/5.png";
import solar6 from "../assets/enviro/solar/6.png";

import VideoSwitch from "../components/videoSwitch";

// Unsure why I decided to do this, copying the entire string would be probably fine
const solarVideos = {
  0: ["874705974", "85fc20bd82059406bb21248abb294617fef65fb5550908d7a689d7230383c659"],
  1: ["874706000", "f8d72d14208be8cdaa41108085505e66e5dff77e5aed1045f3a0a8cb07b15de9"],
  2: ["874706081", "2f285da7e9192dc20c68c8d4d9817854e7bb6a68b04739df6c647f28d2704f5c"],
  3: ["874706142", "f32b48c8e986af5e3f65e6112d6a055a48325adf95bb7eb9fb72a64c38257242"],
  4: ["874706172", "243c1feeed075c72051513528376d405454b7c17e894d07c3922576f05bad2e8"],
  5: ["874706198", "21c5c88f953748b42219b9671260d66a0c29ab02641f4eca0f15603e5a718f89"],
  6: ["874706224", "a4dc8ef57ab392df259f1a43176246a7084a69b1800ba8c123e76b1b5c019bfd"]
}

const fossilVideos = {
  0: ["874705647", "d29720de39d9239e5dd2351375f8f5cc6c07114d7ad5e9b3a4f81c9c1306254e"],
  1: ["874705668", "20b7185d7e0b3bb879bc09fc4e2f27bbe9badbc02711aa78da325206637bc051"],
  2: ["874705713", "d677e24b43632c414f963abac4cdc5a26187a3bc2c984a9bad8e773e728f4722"],
  3: ["874705754", "21b3c4b82e5b7a659bcda781bfee4c278d7d948e19511a00d6d69fa0fb972ac4"],
  4: ["874705806", "14e03830cb833367995cb3beac8ddf09334ae04a7f1d42219573753b1d9722e5"],
  5: ["874705859", "4848fb037e95c37322f52a9b17c8007233f6273e17183cceb1daaf145725ed90"],
  6: ["874705952", "464234fc953117bb516d015a71c76bb5a7b81c7a60c14d2f0f95be7a83bab702"]


}

const buildVimeoLink = (key, isSolar, [id, signature]) => {
  const fileName = `${isSolar ? 'solar' : 'fossil'}${key}%20%281080p%29.mp4`
  return `https://player.vimeo.com/progressive_redirect/download/${id}/rendition/1080p/${fileName}?loc=external&signature=${signature}`
}


const fossilPopup = [
  {
    t: "Pollution",
    d: "Pollution caused by fossil fuels such as Ozone Smog and ground water impurity kills and costs billions in healthcare every year.",
    i: fossil1,
  },
  {
    t: "5x Fires",
    d: "Fires are increasing in frequency and size and incur around 25 billion in energy-related costs every year.",
    i: fossil2,
  },
  {
    t: "Huge Spending",
    d: "Ageing infrastructure equates to trillions in maintenance and upgrade costs.",
    i: fossil3,
  },
  {
    t: "Rising costs",
    d: "Average energy costs have risen over 50% in the last 10 years and are set to rise another 10% this year alone.",
    i: fossil4,
  },
];

const solarPopup = [
  {
    t: "Clean Air",
    d: "Breath easy knowing you are supporting a healthier future for our children and our planet.",
    i: solar1,
  },
  {
    t: "Renewable",
    d: "Nothing feels better than knowing you are connected to the sun - our endless and clean renewable energy source.",
    i: solar2,
  },
  {
    t: "Reliable",
    d: "Keep your power on and help support your community during power grid failures.",
    i: solar3,
    backup: true,
  },
  {
    t: "Low Cost",
    d: "Predictable and lower cost electricity for today and for tomorrow.",
    i: solar4,
  },
  {
    t: "Freedom",
    d: "Freedom to chose clean reliable electricity at will and control when it is used",
    i: solar5,
    backup: true,
  },
  {
    t: "Jobs",
    d: "One out of every 100 new jobs created is within the solar industry, employing people across the US.",
    i: solar6,
  },
];


const renderColumns = (columns) => {
  return columns.map((c) => (
    <div className="col">
      <img src={c.i} />
      <div className="desc">
        <h2>{c.t}</h2>
        <p>{c.d}</p>
        {c.backup ? (
          <p style={{ fontSize: "0.5em", marginTop: "1.5em" }}>
            *with battery backup
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  ));
};
const EnviroVideo = ({ id }) => {
  const [isSolar, setIsSolar] = useState(true);
  const [infoOpen, setInfoOpen] = useState(false);
  return (
    <S.Background className="min-w-1280" isLegacy={id == 0}>
      <S.Video
        playsInline={true}
        autoPlay={true}
        muted={true}
        loop={true}
        className={isSolar ? "" : "show"}
        isLegacy={id == 0}
      >
        <source src={buildVimeoLink(id, false, fossilVideos[id])} type="video/mp4" />
      </S.Video>
      <S.Video
        playsInline={true}
        autoPlay={true}
        muted={true}
        loop={true}
        className={isSolar ? "show" : ""}
        isLegacy={id == 0}
      >
        <source src={buildVimeoLink(id, true, solarVideos[id])} type="video/mp4" />
      </S.Video>

      <S.Container onClick={() => {
        setInfoOpen(!infoOpen);
      }}>
        <div>
          <S.Title className={isSolar ? "solar" : ""}>
            Environmental Impact
          </S.Title>
          <S.SubTitle className={isSolar ? "solar" : ""}>
            With an ageing electric grid, energy costs on the rise, and endless
            environmental
            <br />
            hazards brought on by consuming fossil fuels… it’s time for a
            change.
          </S.SubTitle>
        </div>
      </S.Container>
        <S.SwitchContainer>
          <a
            onClick={() => {
              if (isSolar) {
                setIsSolar(false);
              }
            }}
          >
            <img src={fossilIcon} className={isSolar ? "" : "show"} />
          </a>
          <div style={{ position: "relative" }}>
            <VideoSwitch mode={isSolar} updateMode={(val) => setIsSolar(val)} />
          </div>
          <a
            onClick={() => {
              if (!isSolar) {
                setIsSolar(true);
              }
            }}
          >
            <img src={solarIcon} className={isSolar ? "show" : ""} />
          </a>
        </S.SwitchContainer>

      <S.Modal className={infoOpen ? "show" : ""}>
        {isSolar ? renderColumns(solarPopup) : renderColumns(fossilPopup)}
        <S.ModalCircle />
      </S.Modal>
      <S.InfoToggle
        onClick={() => {
          setInfoOpen(!infoOpen);
        }}
      >
        <img src={infoIcon} className={!infoOpen ? "show" : ""} />
        <img src={infoCloseIcon} className={infoOpen ? "show" : ""} />
      </S.InfoToggle>
    </S.Background>
  );
};

export default EnviroVideo;
