import { jsx } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import React from "react"; // eslint-disable-line
import tw from "twin.macro";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";

import getCost from "./utils/getCost";
import { getProposalData, putProposalData } from "./utils/network";

import createGraphData from "./utils/createGraphData";
import Layout from "./components/layout";
import Loading from "./components/loading";
import Hero from "./sections/hero";
import Design from "./sections/design";
import Production from "./sections/production";
import Versus from "./sections/versus";
import Enviro from "./sections/enviro";
import EnviroVideo from "./sections/enviro-video";
import Steps from "./sections/steps";
import SystemSpecs from "./sections/system-specs";
import Footer from "./sections/footer";

import Rebates from "./sections/rebates";
import demoData from "./demoData";

import {
  adjustGenabilityPostSeriesData,
  adjustGenabilitySavingsSummary,
  getAccumulativeSavings,
  getAccumulativeCost,
  getYearlySavings,
} from "./utils/versusDataModifications";

const Wrapper = tw.div`
  flex items-center justify-center flex-col
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingComplete: true, // REMBMEBER to change back
      proposal: false,
      documents: [],
      keepITC: false,
      renderVersusGraph: false,
      versus: false,
      versusKeepITC: false,
      fullSavingsTerm: 25,
      summaryRegionId: 0,
      yearlyBill: 0
    };
  }

  componentDidMount = async () => {
    const { match } = this.props;
    const { params } = match;
    const { proposalRecordID, dateCreated } = params;
    const { proposal, documents, finance, inverter, panel, battery, customisations, numberOfPanelGroupsFromDesign, summaryRegion, yearlyBill = 0 } =
      await getProposalData({
        dateCreated: parseInt(dateCreated),
        proposalRecordID: parseInt(proposalRecordID),
      });
    if (!Object.keys(proposal).length === 0 || !documents.length)
      alert("Sorry there has been an issue obtaining your proposal");
    else {
      const mutatedProposal = {
        ...proposal,
        numberOfPanelGroupsFromDesign,
        dateCreated: parseInt(dateCreated),
      };

      const { financeProgramTerm } = mutatedProposal;
      let fullSavingsTerm = this.state.fullSavingsTerm;
      if (
        financeProgramTerm &&
        financeProgramTerm.length > 1 &&
        financeProgramTerm.split(" ").length == 2
      ) {
        if (financeProgramTerm.split(" ")[0] == 30) {
          fullSavingsTerm = 30;
        }
      }

      if (mutatedProposal.isLease) {
        fullSavingsTerm = 30
      }

      this.setState(
        {
          proposal: mutatedProposal,
          fullSavingsTerm,
          documents,
          finance,
          inverter,
          panel,
          battery,
          customisations,
          summaryRegionId: summaryRegion,
          yearlyBill
        },
        () => {
          this.getGenabilitySavingsFiguresAndGraph();
        }
      );
    }
  };

  updateProposal = async (updates = []) => {
    const { match } = this.props;
    const { params } = match;
    const { proposalRecordID, dateCreated } = params;
    const { proposal, documents } = await putProposalData({
      dateCreated: parseInt(dateCreated),
      proposalRecordID: parseInt(proposalRecordID),
      updates,
    });
    if (!Object.keys(proposal).length === 0 || !documents.length)
      alert("Sorry there has been an issue obtaining your proposal");
    else {
      this.setState(
        {
          proposal: {
            // Mutate
            ...proposal,
            dateCreated: parseInt(dateCreated),
          },
          documents,
        },
        () => {
          this.getGenabilitySavingsFiguresAndGraph();
        }
      );
    }
  };

  async getGenabilitySavingsFiguresAndGraph() {
    const genabilityPrefix = "sunobi-kota-";
    const { fullSavingsTerm, proposal } = this.state;
    const {
      proposalRecordID,
      utilityEscalator,
      estimatedProductionOverride,
      totalAnnualEnergyConsumption,
      totalAnnualEnergyConsumptionModified,
      paymentInitial,
      paymentAdjusted,
      paymentFlex = 0,
      paymentBest,
      totalAnnualEnergyCost,
      financeProgramTerm,
      offset,
      fundingPartnerName,
      financeProgramRate,
      packagePrice,
      summaryTaxCredit,
      stateRebate = 0,
      utilityRebate = 0,
      totalRebates = 0,
      applyFederalRebate,
      applyStateRebate,
      applyUtilityRebate,
      initialTerm = 0,
      isLease = false,
      leaseEscalator = 0
    } = proposal;

    const isCash = fundingPartnerName == "Cash";

    const threeWayValue =
      applyFederalRebate &&
        (stateRebate > 0 ? applyStateRebate == 1 : true) &&
        (utilityRebate > 0 ? applyUtilityRebate == 1 : true)
        ? 1
        : !applyFederalRebate &&
          !(stateRebate > 0 ? applyStateRebate : false) &&
          !(utilityRebate > 0 ? applyUtilityRebate : false)
          ? 0
          : 0.5;

    const secondPayment = applyFederalRebate ? paymentInitial : paymentAdjusted;

    const applicableIncentives = isCash
      ? summaryTaxCredit + stateRebate + utilityRebate
      : (applyFederalRebate ? summaryTaxCredit : 0) +
      (stateRebate && applyStateRebate ? stateRebate : 0) +
      (utilityRebate && applyUtilityRebate ? utilityRebate : 0);

    const loanTerm = financeProgramTerm.split(" ")
      ? financeProgramTerm.split(" ")[0]
      : 0

    let totalUtilityTopupCost = 0;
    if (
      totalAnnualEnergyConsumptionModified - estimatedProductionOverride >
      0
    ) {
      // We need to get the true utility topup cost via genability
      const summary = await getCost({
        providerAccountId: genabilityPrefix + proposalRecordID,
        consumption: (1 - offset) * totalAnnualEnergyConsumptionModified,
      });
      if (summary.totalCost) {
        totalUtilityTopupCost = summary.totalCost;
      } else {
        alert("There has been an error calculating utility top up");
      }
    }

    const versus = createGraphData({
      utilityEscalator,
      firstPayment: paymentFlex != paymentInitial ? paymentFlex : paymentInitial,
      secondPayment:
        // This is fucked up but we are using the second Payment for the
        threeWayValue == 0 ? paymentInitial : secondPayment,
      totalUtilityTopupCost,
      totalAnnualEnergyCost,
      fullSavingsTerm,
      loanTerm,
      isCash,
      packagePrice,
      taxCredit: applicableIncentives,
      initialTerm,
    });


    const versusKeepITC = createGraphData({
      utilityEscalator,
      firstPayment: paymentFlex != paymentInitial ? paymentFlex : paymentInitial,
      secondPayment: paymentAdjusted,
      totalUtilityTopupCost,
      totalAnnualEnergyCost,
      fullSavingsTerm,
      loanTerm,
      isCash,
      packagePrice,
      taxCredit: summaryTaxCredit,
      initialTerm,
    });


    const versusBest = createGraphData({
      utilityEscalator,
      firstPayment: isLease ? paymentInitial : paymentFlex != paymentInitial ? paymentFlex : paymentInitial,
      secondPayment: isLease ? paymentInitial : paymentBest,
      totalUtilityTopupCost,
      totalAnnualEnergyCost,
      fullSavingsTerm,
      loanTerm,
      isCash,
      packagePrice,
      taxCredit: summaryTaxCredit + stateRebate + utilityRebate,
      initialTerm,
      isLease,
      leaseEscalator
    });


    this.setState(
      {
        renderVersusGraph: false,
      },
      () => {
        this.setState({
          renderVersusGraph: true,
          versus,
          versusKeepITC,
          versusBest,
          isCash,
        });
      }
    );
  }

  async switchITC(val) {
    const updates = [
      {
        fid: 1232,
        value: !val,
      },
      {
        fid: 1215,
        value: !val,
      },
      {
        fid: 1213,
        value: !val,
      },
    ];
    this.updateProposal(updates);
  }

  // Magic numbers but mergh

  getInstallerName = (id) => {
    switch (id) {
      case 2:
        return "Titan Solar Power";
      case 3:
        return "Empire Solar Group";
      case 4:
        return "Kota Energy Group";
      case 5:
        return "Element Solar Energy";
      case 6:
        return "Core Energy";
      case 7:
        return "Get Solarize";
      case 8:
        return "Sun First";
      case 9:
        return "Stockdale Solar";
      case 10:
        return "Urban Solar";
      case 11:
        return "SolarGoingUP";
      case 12:
        return "Milholland";
      case 13:
        return "Secure Roofing & Solar";
      case 14:
        return "Sunergy Solar";
      case 15:
        return "Alternative Solar";
      case 17:
        return "Pacific Energy Network";
      case 18:
        return "AgSolar";
      case 19:
        return "Texas Solar";
      case 20:
        return "Our World Energy";
      case 21:
        return "Ecovole";
      case 22:
        return "Nationwide";
      case 23:
        return "Lonestar Solar";
      case 24:
        return "ENSOLAR";
      case 25:
        return "Headline Solar";
      case 26:
        return "Firefly Solar";
      case 27:
        return "ReNu";
      case 28:
        return "Inty Power";
      case 29:
        return "Fastrac Energy Services";
      case 30:
        return "Florida Power Management";
      case 31:
        return "TRON Solar";
      case 32:
        return "Quantum Solar Power";
      case 33:
        return "Will Power Solar";
      case 34:
        return "Sun Energy, Co";
      case 35:
        return "Green Light Solar";
      case 36:
        return "Summit Energy Group";
      case 37:
        return "Cornerstone Construction";
      case 38:
        return "Green Day Power";
      case 39:
        return "Infenergy Solar Solutions";
      case 40:
        return "Freedom Solar Energy";
      case 41:
        return "Infinity Solar Systems, LLC";
      case 42:
        return "Generation Solar, LLC";
      case 43:
        return "Kin Home";
      case 44:
        return "Flo Energy, LLC";
      case 45:
        return "Elan Solar";
      case 46:
        return "Equity Solar";
      case 47:
        return "AC/DC Solar, LLC";
      case 48:
        return "Raven Solar Services, LLC";
      case 49:
        return "ECO Home Inc";
      case 50:
        return "Touch of Grey Solar";
      case 51:
        return "GR8FL Solar, LLC";
      case 52:
        return "Bell Solar & Electrical Systems";
      case 53:
        return "SunGrid Solar";
      case 54:
        return "Mann Solar";
      case 55:
        return "On Point Solar Power";
      case 56:
        return "G3 Solar";
      case 57:
        return "The Solar Exchange";
      case 58:
        return "Greenstar Power, LLC";
      case 59:
        return "Dawn to Dusk Solar Texas, LLC";
      case 60:
        return "Fusion Power";
      case 61:
        return "Grity Power, LLC";
      case 62:
        return "Apollo Solar Energy and Investments, LLC";
      case 63:
        return "Hero Construction Group, Inc.";
      case 65:
        return "Phoenix Business Solutions, LLC";
      case 66:
        return "816 Solar Pros";
      case 67:
        return "GreenBrilliance, LLC";
      case 68:
        return "Top Tier Solar Solutions";
      case 69:
        return "Sun Source Installations, LLC";
      case 70:
        return "Flex Energy";
      case 71:
        return "South Plains Solar";
      case 72:
        return "Spring Solar, LLC";
      case 73:
        return "Volt Solar Energy";
      case 74:
        return "Big Dog Renewable Energy, LLC";
      case 75:
        return "Smart Home Energy Source, LLC";
      case 76:
        return "ABP Electrical Systems, Inc.";
      case 77:
        return "Solean Energy";
      case 78:
        return "Convert Solar";
      case 79:
        return "Glyde Solar";
      case 80:
        return "Solcius, LLC";
      case 82:
        return "Tempo Solar";
      case 83:
        return "Action Solar";
      case 85:
        return "S365 Incorporated";
      case 86:
        return "Titanium Solar";
      default:
        "";
    }
  };

  render() {
    const {
      proposal,
      documents,
      renderVersusGraph,
      versus,
      keepITC,
      versusBest,
      versusKeepITC,
      loadingComplete,
      isCash,
      fullSavingsTerm,
      yearlyBill
    } = this.state;

    const {
      proposalRecordID,
      dateCreated,

      // Hero
      installerRecordID,
      leadName,
      leadFullAddress,

      // design and prod/cons
      systemSizeKW,
      totalNumberOfPanels,
      estimatedProductionOverride,
      totalAnnualEnergyConsumption,
      totalAnnualEnergyConsumptionModified,
      offset,
      paymentInitial,
      paymentAdjusted,
      paymentFlex = 0,
      stateRebate = 0,
      utilityRebate = 0,
      applyFederalRebate,
      applyStateRebate,
      applyUtilityRebate,
      utilityMode = "auto",
      utilityData = [],
      numberOfSolarArrays = 0,
      numberOfPanelGroupsFromDesign = 0,
      financeProgramTerm = "",
      financeProgramRate,
      initialTerm,

      isLease = false,
      leaseEscalator = 0
    } = proposal;

    const threeWayValue =
      applyFederalRebate &&
        (stateRebate > 0 ? applyStateRebate == 1 : true) &&
        (utilityRebate > 0 ? applyUtilityRebate == 1 : true)
        ? 1
        : !applyFederalRebate &&
          !(stateRebate > 0 ? applyStateRebate : false) &&
          !(utilityRebate > 0 ? applyUtilityRebate : false)
          ? 0
          : 0.5;

    const firstPayment = isLease ? paymentInitial : paymentFlex != paymentInitial ? paymentFlex : paymentInitial

    const secondPayment = applyFederalRebate ? paymentInitial : paymentAdjusted;


    const loanTerm = financeProgramTerm.split(" ")
      ? financeProgramTerm.split(" ")[0]
      : 0

    const loanTermInMonths = loanTerm * 12

    const percentageAtFirst = initialTerm / loanTermInMonths

    const levelizedRate =
      percentageAtFirst * (firstPayment * 12) / estimatedProductionOverride +

      (1 - percentageAtFirst) * (secondPayment * 12) / estimatedProductionOverride

    return proposal && loadingComplete ? (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          {/* <title>{`${leadName}'s Solar Energy Report from ${this.getInstallerName(
            installerRecordID
          )}`}</title> */}
          <title>{`${leadName}'s Solar Energy Report from ${this.state.customisations.installerName}`}</title>
        </Helmet>
        <Wrapper style={{ minWidth: "1280px" }}>
          <Hero
            installerRecordID={installerRecordID}
            leadName={leadName}
            leadFullAddress={leadFullAddress}
            customisations={this.state.customisations}
            loadingComplete={loadingComplete}
          />
          <Design
            systemSize={systemSizeKW}
            totalNumberOfPanels={totalNumberOfPanels}
            annualProduction={estimatedProductionOverride}
            documents={documents}
            panelAsset={this.state.panel}
            inverterAsset={this.state.inverter}
            batteryAsset={this.state.battery}
          />
          <Production
            dateCreated={dateCreated}
            proposalRecordID={proposalRecordID}
            production={estimatedProductionOverride}
            consumptionOriginal={totalAnnualEnergyConsumption}
            consumption={totalAnnualEnergyConsumptionModified}
            offset={offset}
            numberOfSolarArrays={numberOfPanelGroupsFromDesign ? numberOfPanelGroupsFromDesign : numberOfSolarArrays}
            utilityMode={utilityMode}
            utilityData={utilityData}
          />
          {proposal && (
            <Versus
              proposal={proposal}
              graphData={
                isCash || isLease
                  ? versusBest
                  : threeWayValue == 0
                    ? versusKeepITC
                    : versus
              }
              shouldRender={renderVersusGraph}
              keepITC={keepITC}
              paymentAdjusted={paymentAdjusted}
              updateProposalFn={this.updateProposal}
              fullSavingsTerm={fullSavingsTerm}
              levelizedRate={levelizedRate}
              isLease={isLease}
              leaseEscalator={leaseEscalator}
            />
          )}
          {
            isLease ? <></>
              :
              <Rebates proposal={proposal} isCash={isCash} />
          }

          <EnviroVideo id={this.state.summaryRegionId} />
          <Steps />
          <SystemSpecs
            proposal={proposal}
            dataKeepITC={versusKeepITC}
            isCash={isCash}
            dataNoITC={isCash ? versusBest : versus}
            documents={this.state.documents}
            fullSavingsTerm={fullSavingsTerm}
            installerName={this.state.customisations.installerName}
            customisations={this.state.customisations}
            levelizedRate={levelizedRate}
            financeProgramTerm={financeProgramTerm}
            financeProgramRate={financeProgramRate || 0}
            initialTerm={initialTerm || 18}
            totalTerm={loanTermInMonths}
            yearlyBill={yearlyBill}
            finance={this.state.finance}
          />
          <Footer />
        </Wrapper>
      </Layout>
    ) : (
      <Loading
        proposal={proposal}
        designImages={documents.filter(
          (d) => d.type == "Customer Design Image"
        )}
      />
    );
  }
}

export default withRouter(App);
