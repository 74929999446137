import React, { useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import SigningButtons from "../components/signingButtons";

import DashedBorder from "../assets/icons/dashedBorder.svg";
import DashedBorderLarge from "../assets/icons/dashedBorderLarge.svg";

import deathIcon from "../assets/icons/systemSpecs/death.svg";
import itcIcon from "../assets/icons/systemSpecs/itc.svg";
import specsUtility from "../assets/specs-utility.png";
import specsSolar from "../assets/specs-solar.png";

import warrantyLabor from "../assets/warranties/labo.png";
import warrantyManu from "../assets/warranties/manu.png";
import warrantyPerf from "../assets/warranties/perf.png";
import warrantyRoof from "../assets/warranties/roof.png";

import solarSeperator from "../assets/solar-seperator.png";

import {
  Background,
  Container,
  Column,
  SubTitle,
  Disclaimer,
  Row,
  Left,
  Icon,
  Key,
  Value,
  Cost,
  OffsetDiv,
  OffsetValue,
  OffsetDescription,
  MonthlyTotal,
  Box,
  Pagination,
} from "../styles/system-specs-styles";

import formatter from "../utils/formatter";

import bill from "../assets/icons/fresh/icon-dollardoc.svg";
import consumptionIcon from "../assets/icons/fresh/icon-electric.svg";
import costIcon from "../assets/icons/fresh/icon-dollarsign.svg";

import escalatorIcon from "../assets/icons/fresh/icon-graph.svg";
import companyIcon from "../assets/icons/fresh/icon-pilon.svg";

import systemSizeIcon from "../assets/icons/fresh/icon-kwpanel.svg";
import productionIcon from "../assets/icons/fresh/icon-sunpanel.svg";

import totalCostIcon from "../assets/icons/systemSpecs/totalCost.svg";
import calculatorIcon from "../assets/icons/fresh/icon-calculator.svg";
import thumbsUp from "../assets/icons/fresh/icon-thumbup.svg";

import offsetIcon from "../assets/icons/systemSpecs/offset.svg";
const renderRows = (rows, type) =>
  rows.map((r, i) => (
    <Row key={`${type}-${i}`}>
      <Left>
        <Icon>
          <img src={r.icon} style={{ width: "90%" }} />
        </Icon>
        <Key>{r.key}</Key>
      </Left>
      <Value>{r.val}</Value>
    </Row>
  ));

const getUtilityCost = (yearlyBill, utilityEscalator, offset, leadFullAddress, batteryRecordID, year = 0) => {
  const isCalifornia = leadFullAddress.includes("California")
  let utilityCost = yearlyBill / 12
  if (year == 1) {
    utilityCost = yearlyBill * (1 + utilityEscalator * 1.5 / 100) / 12
  }

  if (isCalifornia && batteryRecordID && offset > 1) {
    return 0;
  }
  return utilityCost
}
const SystemSpecs = ({
  proposal,
  documents,
  dataNoITC,
  isCash,
  fullSavingsTerm,
  installerName,
  customisations,
  levelizedRate,
  financeProgramTerm,
  financeProgramRate,
  finance = {},
  initialTerm,
  totalTerm,
  yearlyBill = 0
}) => {
  const {
    systemSizeKW,
    estimatedProductionOverride: production,
    totalAnnualEnergyConsumption: consumption,
    totalAnnualEnergyConsumptionModified,
    totalAnnualEnergyCost,
    utilityEscalator,
    offset,
    paymentFlex = 0,
    packagePrice,
    paymentInitial = 0,
    paymentAdjusted = 0,
    utilityProviderName,
    signingCompletedFinanceDocs = false,
    summaryTaxCredit,
    stateRebate = 0,
    utilityRebate = 0,
    applyFederalRebate,
    isLease = false,
    batteryRecordID = 0,
    leadFullAddress = ""


  } = proposal;

  console.log({financeAtSystemSpecs: finance})


  const secondPayment = applyFederalRebate ? paymentInitial : paymentAdjusted;

  const utilityRows = [
    {
      key: "Utility Company",
      val: utilityProviderName,
      icon: companyIcon,
    },
    {
      key: "Current Annual Consumption",
      val: totalAnnualEnergyConsumptionModified
        ? formatter(totalAnnualEnergyConsumptionModified, 0) + " kWh"
        : "",
      icon: consumptionIcon,
    },
    {
      key: "Estimated Cost Per KWh",
      val:
        consumption && totalAnnualEnergyCost
          ? `$${formatter(totalAnnualEnergyCost / consumption, 3)}/kWh`
          : "",
      icon: costIcon,
    },
    {
      key: "Annual Utility Bill",
      val: totalAnnualEnergyCost
        ? "$" + formatter(totalAnnualEnergyCost, 0)
        : "",
      icon: bill,
    },
    {
      key: "Annual Utility Price Escalator",
      val: utilityEscalator + "%",
      icon: escalatorIcon,
    },
    {
      key: `Estimate ${fullSavingsTerm} Year Cost`,
      val:
        "$" +
        (dataNoITC && dataNoITC.cumulativeCost
          ? formatter(
            dataNoITC.cumulativeCost[dataNoITC.cumulativeCost.length - 1],
            0
          )
          : 0),
      icon: deathIcon,
      width: "30%",
    },
  ];

  const systemRows = [
    {
      key: "System Size",
      val: formatter(systemSizeKW, 3) + " kW",
      icon: systemSizeIcon,
      width: "90%",
    },
    {
      key: "Year 1 Solar Production",
      val: production ? formatter(production, 0) + " kWh" : "",
      icon: productionIcon,
    },
    {
      key: "Estimated Cost Per KWh",
      val:
        proposal.fundingPartnerName == "Cash"
          ? production
            ? `$${formatter(
              (packagePrice -
                summaryTaxCredit -
                stateRebate -
                utilityRebate) /
              (production * fullSavingsTerm),
              3
            )}/kWh`
            : ""
          : production && (paymentFlex || paymentInitial)
            ? `$${formatter(levelizedRate, 3)}/kWh`
            : "",
      icon: costIcon,
      width: "30%",
    },
  ];

  const calculatedOffset = offset * 100;
  const data = [{ id: "1", name: "L1", value: calculatedOffset }];
  if (calculatedOffset < 100) {
    data.push({ id: "2", name: "L2", value: 100 - calculatedOffset });
  }
  const circleSize = 250;

  const [monthlySwitcher, setMonthlySwitcher] = useState(0);

  const renderMonthlyPayment = () => {
    if (!dataNoITC || !dataNoITC.after) {
      return 0;
    }

    const utilityCost = getUtilityCost(yearlyBill, utilityEscalator, offset, leadFullAddress, batteryRecordID, monthlySwitcher)
    // Then we show the originals
    if (monthlySwitcher == 0) {
      return formatter(
        (isLease ? paymentInitial : paymentFlex != paymentInitial ? paymentFlex : paymentInitial) + utilityCost,
        0
      );
    }

    return formatter(secondPayment + utilityCost);

  };

  const apr = (financeProgramRate * 100).toFixed(2)
  const remaindingTerm = totalTerm - initialTerm
  const showLenderDisclaimer = proposal.fundingPartnerName == "Sunlight"

  const warranties = [
    { img: warrantyLabor, years: customisations.laborWarrantyTerm },
    { img: warrantyRoof, years: customisations.roofPenetrationWarrantyTerm },
    { img: warrantyManu, years: customisations.manufacturersWarrantyTerm },
    { img: warrantyPerf, years: customisations.performanceGuaranteeTerm },

  ]
  return (
    <Background className="min-w-1280" style={{ backgroundColor: `#fff` }}>
      <Container>
        <Column>
          <SubTitle>
            <span style={{ color: "#777777" }}>SPECIFICATIONS:</span> CURRENT
            Utility
          </SubTitle>
          <Box className="utility">
            <img
              src={specsUtility}
              className="big-image"
              style={{ height: "207px" }}
            />
            <div style={{ flex: 1 }}>{renderRows(utilityRows, "utility")}</div>
          </Box>
          <SubTitle style={{ marginTop: "65px" }}>
            <span style={{ color: "#777777" }}>SPECIFICATIONS:</span> New Solar
            System
          </SubTitle>

          <Box className="system">
            <img
              src={specsSolar}
              className="big-image"
              style={{ height: "120px" }}
            />
            <div style={{ flex: 1 }}>{renderRows(systemRows, "system")}</div>
            <div style={{ display: "flex", justifyContent: 'center', width: '100%' }}>
              {warranties.filter(w => w.years).map(w => (
                <div className="warranty">
                  <img src={w.img} />
                  <p>{w.years ? w.years : 0}</p>
                </div>
              ))}
            </div>

            <img src={solarSeperator} className="border" />
          </Box>

          <Disclaimer>
            {installerName == "The Solar Company" ? "CT2 Solar LLC dba The Solar Company" : installerName} does not provide tax or legal
            advice. You should consult your tax advisor for more information.
            The interest rate provided in this proposal is subject to credit
            approval by the financing provider for your loan. The data provided
            in this proposal is a preliminary estimate and does not represent a
            binding agreement or obligation. No party provides any guarantees,
            warranties, or representations regarding the production, utility
            rate increases, or any other data in this sales proposal. This
            proposal is a preliminary estimate and not an approval for
            financing.
          </Disclaimer>
          {showLenderDisclaimer ?
            <Disclaimer>
              No down payment required. The first monthly payment on the loan is due approximately 90 days after installation. The monthly payments amounts due, the annual percentage rate ("APR") and the term of the loan are noted herein. If you make a voluntary payment equal to 30% of your loan amount by payment 16, the monthly payment amount of your remaining payments will remain the same as your first 16 payments. If no voluntary payment is made, the remaining monthly payments on your loan will increase as noted above. If you make a voluntary payment of less than 30% of your loan amount, your monthly payment for payments 17 onwards will be higher than the monthly payment amount for your first 16 payments. Conversely, if you make a voluntary payment of greater than 30% of your loan amount before your 16th payment, your subsequent monthly payments will be reduced to reflect the payment of a lesser principal balance than originally assumed for the balance of the term. The payment amount for payments 17+ assumes that you make a voluntary payment during the initial period based on your receipt of federal, state and/or local incentives. These federal, state and/or local incentives may not be available at the time you request them. Please carefully review the details of your loan, including the payment amounts, provided in your loan agreement. The numbers presented here are estimates only. The payment term stated in your loan documents will govern. Your loan agreement may assume you have not elected to pay by ACH.
            </Disclaimer>
            : <Disclaimer></Disclaimer>
          }
        </Column>
        <Column>
          <SubTitle style={{ paddingLeft: 0 }}>SOLAR COSTS</SubTitle>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div style={{ width: "50%", marginRight: "5%" }}>
              <div
                style={{
                  display: "flex",
                  height: "50px",
                  alignItems: "center",
                  paddingTop: "20px",
                }}
              >
                <Icon style={{ width: "14%" }}>
                  <img src={totalCostIcon} />
                </Icon>
                <Key>Total Solar Cost</Key>
              </div>
              <Cost>${formatter(packagePrice, 0)}</Cost>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Icon style={{ width: "14%" }}>
                  <img src={bill} />
                </Icon>
                <Key>
                  {monthlySwitcher == 0
                    ? "New Monthly "
                    : `After ${initialTerm} Months `}
                  Utility Bill
                </Key>
              </div>
              <Cost
                style={{
                  color: "#777777",
                  paddingTop: "10px",
                  paddingBottom: "5px",
                }}
              >
                ${formatter(getUtilityCost(yearlyBill, utilityEscalator, offset, leadFullAddress, batteryRecordID, monthlySwitcher))}
              </Cost>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "10px",
                  paddingBottom: "20px",
                }}
              >
                <Cost
                  style={{
                    color: offset > 1 ? "#777777" : "#FF3636",
                    padding: 0,
                  }}
                >
                  {offset > 1 ? 0 : formatter((offset - 1) * 100, 0)}%
                </Cost>
                <Key
                  style={{
                    fontSize: "0.8em",
                    lineHeight: "1.3em",
                    color: "#777777",
                  }}
                >
                  {offset >= 1
                    ? ""
                    : "Required energy not produced by solar purchased from grid"}
                </Key>
              </div>
              <div
                style={{
                  width: "90%",
                  marginTop: "15px",
                }}
              >
                <img src={DashedBorder} style={{ width: "70%" }} />
              </div>
              {
                finance ?
                  <div style={{marginTop: "25px"}}>
                    <img src={finance.logoURL} style={{ maxHeight: "30px" }} />
                    <Key style={{ marginTop: "10px", paddingLeft: "0px" }}>{finance.category}</Key>
                    <Key style={{ marginTop: "10px", paddingLeft: "0px" }}>{finance.apr.toFixed(2)}%</Key>
                    <Key style={{ marginTop: "10px", paddingLeft: "0px" }}>{finance.termInMonths / 12} years ({finance.introTermInMonths} months initial term)</Key>
                  </div>
                  :
                  <></>
              }
              <div
                style={{
                  display: "flex",
                  marginTop: "25px",
                  alignItems: "center",
                }}
              >
                <Icon style={{ width: "14%", marginLeft: "-10px" }}>
                  <img src={bill} />
                </Icon>
                <Key>
                  {monthlySwitcher == 0
                    ? "Monthly Solar Bill"
                    : `After ${initialTerm} Months Solar Bill`}
                </Key>
              </div>
              <Cost style={{ color: "#97D33A" }}>
                $
                {formatter(
                  monthlySwitcher == 0
                    ? isLease ? paymentInitial : paymentFlex != paymentInitial
                      ? paymentFlex
                      : paymentInitial
                    : secondPayment,
                  0
                )}
              </Cost>
              
              
            </div>

            <div style={{ position: "relative", marginTop: "0px" }}>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  height: "50px",
                  paddingTop: "20px",
                  opacity: isLease ? 0 : 1
                }}
              >
                <Icon style={{ width: "14%" }}>
                  <img src={itcIcon} />
                </Icon>
                <Key style={{ width: "auto" }}>Potential Incentives</Key>
              </div>
              <Cost style={{ color: "#777777", textAlign: "right", opacity: isLease ? 0 : 1 }}>
                ${formatter(summaryTaxCredit + stateRebate + utilityRebate, 0)}
              </Cost>
              <PieChart
                width={circleSize}
                height={circleSize}
                style={{
                  marginTop: "50px",
                  position: "relative",
                  left: "10px",
                }}
              >
                <Pie
                  data={data}
                  dataKey="value"
                  innerRadius="92%"
                  outerRadius="100%"
                  fill="#eee"
                  startAngle={90}
                  endAngle={-270}
                  paddingAngle={0}
                  blendStroke
                >
                  <Cell key="l2" fill="#97D33A" />
                </Pie>
              </PieChart>
              <OffsetDiv
                style={{
                  width: circleSize + "px",
                  height: circleSize + "px",
                  top: "170px",
                  left: "10px",
                }}
              >
                <img src={offsetIcon} style={{ width: "23%" }} />
                <OffsetValue style={{ color: "#97D33A" }}>
                  {formatter(calculatedOffset, 0)}%
                </OffsetValue>
                <OffsetDescription style={{ color: "#97D33A" }}>
                  Solar Offset
                </OffsetDescription>
              </OffsetDiv>
            </div>
          </div>
          <div
            style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
          >
            <img src={DashedBorderLarge} style={{ width: "100%" }} />
          </div>

          <MonthlyTotal>
            <Icon style={{ width: "10%" }}>
              <img src={calculatorIcon} />
            </Icon>
            <Key>
              {monthlySwitcher == 0
                ? "New Monthly"
                : `After ${initialTerm} Months`}{" "}
              Total
            </Key>
            <img src={thumbsUp} style={{ width: "100px" }} />

            <h3 style={{ color: "#97D33A" }}>${renderMonthlyPayment()}</h3>
          </MonthlyTotal>
          {isLease ? <div style={{ width: '100%', height: "2em" }} /> :
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                style={{ opacity: monthlySwitcher == 1 ? 0.2 : 1 }}
                onClick={() => {
                  setMonthlySwitcher(0);
                }}
              >
                <span className="pulse" />
              </Pagination>

              <Pagination
                style={{ opacity: monthlySwitcher == 0 ? 0.2 : 1 }}
                onClick={() => {
                  setMonthlySwitcher(1);
                }}
              >
                <span className="pulse" />
              </Pagination>
            </div>
          }

          <SigningButtons
            installLink={proposal.kotaHIA ? proposal.kotaHIA : ""}
            fundingPartnerName={proposal.fundingPartnerName}
            financeURL={proposal.loanpalFormURL ? proposal.loanpalFormURL : ""}
            jobCreated={proposal.jobCreated}
            documents={documents}
            signingCompletedFinanceDocs={signingCompletedFinanceDocs}
          />
        </Column>
      </Container>
    </Background>
  );
};

export default SystemSpecs;
