import React from 'react'
import tw, { styled } from 'twin.macro'
import formatter from '../utils/formatter'

import enviroBackground from '../assets/enviro-background.jpg'
import plant from '../assets/enviro-plant.png'
import car from '../assets/enviro-car.png'
import trash from '../assets/enviro-trash.png'
import tree from '../assets/enviro-tree.png'


const Enviro = ({ yearlyProduction, systemSize }) => {
  const pounds = (yearlyProduction / 0.88) * 2.2
  const miles = (systemSize * 1000) / 0.3125
  const tons = systemSize / 0.03
  const trees = yearlyProduction * 0.03

  return (
    <Background className="min-w-1280">
      <Container>
        <div>
          <Title>Better for our environment!</Title>
          <SubTitle>
            With an ageing electric grid, energy costs on the rise, and endless
            environmental
            <br />
            hazards brought on by consuming fossil fuels, there’s never been a
            more crucial time to <br />
            switch to a renewable and sustainable source of energy.
          </SubTitle>
        </div>
        <Row>
          <Column>
            <ImgContainer>
              <img src={plant} className="plant" />
            </ImgContainer>
            <div className="content">
              <p>Eliminating over</p>
              <h4>
                <span>{formatter(pounds, 0)}</span> lbs
              </h4>
              <p>
                of coal burnt
                <br />
                every year
              </p>
            </div>
          </Column>
          <Column>
            <ImgContainer>
              <img src={car} className="car" />
            </ImgContainer>
            <div className="content">
              <p>Not driving a car</p>
              <h4>
                <span>{formatter(miles, 0)}</span> miles
              </h4>
              <p>
                every year
                <br />
                <span className="hack">No</span>
              </p>
            </div>
          </Column>
          <Column>
            <ImgContainer>
              <img src={trash} className="trash" />
            </ImgContainer>
            <div className="content">
              <p>Recycling</p>
              <h4>
                <span>{formatter(tons, 0)}</span> tons
              </h4>
              <p>of waste instead of landfill every year</p>
            </div>
          </Column>
          <Column>
            <ImgContainer>
              <img src={tree} className="tree" />
            </ImgContainer>
            <div className="content">
              <p>Planting or saving</p>
              <h4>
                <span>{formatter(trees, 0)}</span> trees
              </h4>
              <p>
                every year
                <br />
                <span className="hack">No</span>
              </p>
            </div>
          </Column>
        </Row>
      </Container>
    </Background>
  )
}

export default Enviro

const Background = styled.div`
  ${tw`w-screen flex bg-cover justify-center`}
  background-image: url(${enviroBackground});
  min-height: 750px;
  background-position: 50% 0;
`
const Container = styled.div`
  ${tw`flex flex-col items-center flex-col justify-between px-8 bg-center py-24`}
  max-width: 1280px;
`

const Title = tw.h1`
    text-2xl uppercase text-center text-white font-light tracking-wider pb-4
`
const SubTitle = tw.h2`
    text-lg text-center font-thin tracking-wider text-white opacity-75
`

const Row = styled.div`
  ${tw`flex justify-center items-start w-full px-10`}
`

const Column = styled.div`
  ${tw`w-1/4 text-center py-10 px-6 text-white`}
  .content {
    ${tw`p-6 py-10 rounded-md`}
    background-color: rgba(247, 245, 245, 0.12);
  }

  p {
    ${tw`text-lg text-center text-white font-light tracking-wider`}
    .hack {
      opacity: 0;
    }
  }
  h4 {
    ${tw`text-2xl text-center text-white font-light tracking-wider`}
    span {
      ${tw`text-3xl font-medium tracking-wider`}
    }
  }
`

const ImgContainer = styled.div`
  ${tw`h-48 flex items-end justify-center`}

  img {
    &.plant {
      width: 700px;
      max-width: 700px;
      margin-left: -315px;
    }
    &.car {
      margin-left: -75px;
    }
    &.trash {
      width: 120px;
    }
    &.tree {
      width: 300px;
      max-width: 300px;
    }
  }
`
