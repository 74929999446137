import React from "react";
import tw, { styled } from "twin.macro";

import footerBackground from "../assets/footer-background.jpg";

const Background = styled.div`
  ${tw`w-screen flex items-start bg-cover`}
  min-height: 800px;
`;
const Container = tw.div`
    container flex items-center flex-col bg-center
`;
const Title = tw.h1`
    text-2xl uppercase text-center text-sunobi-dark font-light tracking-wider pt-32
`;

const Footer = () => {
  return (
    <Background
      className="min-w-1280"
      style={{
        backgroundImage: `url(${footerBackground})`,
        backgroundPosition: "45% 50%",
      }}
    >
      <Container>
        <Title>MAKE A DIFFERENCE WITH SOLAR</Title>
      </Container>
    </Background>
  );
};

export default Footer;
