import tw, { styled } from "twin.macro";

export const Wrapper = styled.div`
  width: 100%;
  ${tw`flex justify-center flex-wrap`}
`;

export const Expander = styled.div`
  opacity: 0;
  transition: all 0.3s linear;
  &.visible {
    opacity: 1;
  }
`;

export const Row = styled.div`
  ${tw`flex items-center`}
`;
export const Rebate = styled.a`
  ${tw`flex justify-between items-center`}
  background: #fff;
  margin: 0 20px;
  width: 210px;
  height: 35px;
  padding: 0 15px;
  border-radius: 20px;
  cursor: pointer;
  &.trans {
    width: 104px;
    background: transparent;
    border: 1px solid rgba(112, 112, 112, 0.2);
    border-radius: 15px;
    justify-content: center;
  }
  &.active {
    justify-content: center;
    width: 104px;
    background: #fff;
    border: none;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const RebateHeader = styled.div`
  top: -40px;
  left: 35px;
  ${tw`absolute flex items-end`}
  &.state {
    top: -35px;
    left: 55px;
  }
  &.utility {
    left: 60px;
  }
`;

export const Label = styled.p`
  ${tw`text-sunobi-light`};
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: 200;
  color: #222222;
  text-transform: uppercase;
  margin: 0;
`;

export const Value = styled.p`
  ${tw`text-sunobi-light`};
  font-size: 24px;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #222222;
  text-transform: uppercase;
  margin: 0;
`;
export const SubLabel = styled.p`
  ${tw`text-sunobi-light`};
  font-size: 8px;
  letter-spacing: 0.1em;
  font-weight: 200;
  color: #222222;
  text-transform: uppercase;
  margin: 0;
`;

export const SubValue = styled.p`
  ${tw`text-sunobi-light`};
  font-size: 16px;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #444444;
  text-transform: uppercase;
  margin: 0;
`;

export const Pig = styled.img`
  width: 24.5px;
  height: 24.5px;
`;

export const Bank = styled.img`
  width: 30.6px;
  height: 22.5px;
`;
