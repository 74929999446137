/**
 * @description Get the projected usage profile for the current year +- 2 months each side
 *
 * @return {boolean}
 */
const url = `/.netlify/functions/getConsumption`;

export default async (shimBody) => {
  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(shimBody),
  }).catch((err) => {
    console.log("Get Consumption Error", err);
  });
  if (res.status == 200) {
    const json = await res.json();
    //console.log({ getConsumptionJSON: json });
    if (json) return json;
    else return false;
  } else return false;
};
