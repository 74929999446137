import tw, { styled } from "twin.macro";

export const Background = styled.div(
  ({isLegacy}) => 
  [
    tw`w-screen flex bg-cover justify-start items-center flex-col`,
    `
      background-color: #000;
      position: relative;
      overflow: hidden;
    `,
    !isLegacy && `height: calc(100vw * 0.665844636251541);`,
    isLegacy && `height: calc(100vw * 0.712401055408971);`
  ]
);
export const Container = styled.div`
  ${tw`flex flex-col items-center bg-center py-24 w-full`}
  max-width: 1280px;
  position: relative;
  height: 100%;
  z-index: 99;
  cursor: pointer;
`;
export const Title = styled.h1`
  ${tw`text-2xl uppercase text-center text-white font-light tracking-wider pb-1`}
  z-index: 99;
  transition: opacity, 0.3s linear;
`;
export const SubTitle = styled.h2`
  ${tw`text-lg text-center font-thin tracking-wider text-white`}
  z-index: 99;
  transition: opacity, 0.3s linear;
`;
export const Video = styled.video(({isLegacy}) => [
  tw`absolute left-0 top-0 opacity-0`,
  `
    transition: opacity, 1s linear;
    overflow-y: hidden;
    &.show {
      transition: opacity, 1s linear;
      opacity: 1;
    }
  `,
  !isLegacy && `height: calc(100vw * 0.665844636251541);`,
  isLegacy && `height: calc(100vw * 0.712401055408971);`
  ]
);

export const SwitchContainer = styled.div`
  position: absolute;
  bottom: 12em;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  ${tw`flex items-center justify-between`}
  img {
    height: 30px;
    opacity: 0.5;
    transition: opacity, 0.3s linear;
    &.show {
      opacity: 1;
    }
    &:hover {
      cursor: pointer;
    }
  }
  & > a {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const InfoToggle = styled.a`
  position: absolute;
  top: 50%;
  margin-top: -230px;
  left: 50%;
  margin-left: -30px;
  width: 30px;
  height: 30px;
  z-index: 999;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    opacity: 0;
    transition: opacity, 0.3s linear;
    &.show {
      opacity: 1;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -215px;
  background-color: rgba(20,20,20,0.65);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  ${tw`flex justify-center items-start`}
  height: 318px;
  z-index: 9;

  opacity: 0;
  transition: opacity, 0.3s linear;
  &.show {
    opacity: 1;
    z-index:888;
  }
  .col {
    width: 140px;
    margin-right: 40px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  img {
    width: 100%;
    height: 140px;
    margin-bottom: 15px;
  }
  .desc{
    padding-left: 10px;
  }
  h2 {
    ${tw`tracking-widest text-white`}
    margin-bottom: 10px;
    font-size: 15px;
  }
  p {
    ${tw`font-thin tracking-widest text-white`}
    font-size: 10px;
    margin-bottom: 0;
  }
  

    
  }
`;

export const ModalCircle = styled.div`
  position: absolute;

  top: -25px;
  left: 50%;
  margin-left: -40px;
  width: 50px;
  height: 25px;
  background-color: rgba(20, 20, 20, 0.65);
  border-radius:50px 50px 0 0
  
`;

