import tw, { styled } from "twin.macro";

export const Background = styled.div`
  ${tw`w-screen flex items-center justify-center bg-cover`}
  min-height: 1100px;
`;
export const Container = styled.div`
  ${tw`flex items-start justify-between px-4 bg-center`}
  max-width: 1280px;
`;
export const Column = styled.div`
  width: 47%;
`;

export const Title = tw.h1`
    text-2xl uppercase text-sunobi-dark font-light tracking-widest pb-12
`;
export const SubTitle = styled.h2`
  ${tw`text-lg font-light tracking-widest text-sunobi-dark uppercase pb-3`}
  padding-left: 20px;
  font-size: 21px;
`;
export const Row = styled.div`
  ${tw`flex justify-between w-full`}
  padding-top: 2px;
  padding-bottom: 3px;
`;
export const Left = tw.div`
    flex
    items-center
    justify-between
    
`;
export const Icon = tw.div`
    flex 
    justify-center
    items-center
    
`;
export const Key = styled.p`
  ${tw`text-xs text-left font-light tracking-wider text-sunobi-dark
    m-0
    w-11/12
    pl-3
    opacity-75`}
  font-size: 15px;
`;
export const Value = styled.p`
  ${tw`text-xs text-right font-light tracking-wider text-sunobi-dark
    m-0 text-right
    items-center
    flex`}
  font-size: 15px;
`;
export const Disclaimer = tw.p`
    mt-8
    text-sunobi-dark
    text-xs
    font-light
    italic
    opacity-75
`;

export const Cost = tw.p`
    text-sunobi-light
    font-medium
    text-2xl
    pb-4
    pt-2
    tracking-wider
`;

export const OffsetDiv = tw.div`
    absolute
    flex
    flex-col
    top-0
    justify-center
    items-center
`;

export const OffsetValue = tw.p`
    font-medium
    text-6xl
    leading-none
    mt-2
`;
export const OffsetDescription = tw.p`
    
    text-sm
    uppercase
    mb-8
`;

export const MonthlyTotal = styled.div`
  ${tw`flex justify-between items-center`}

  p {
  }
  h3 {
    ${tw`font-medium text-6xl leading-none ml-4`}
  }
`;

export const Box = styled.div`
  background-color: #fff;
  padding: 25px 21px 25px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;

  &.utility {
    background-color: #f2f2f2;
  }
  &.system {
    position: relative;

    .border {
      position: absolute;
      width: 190px;
      right: -21px;
      top: 0px;
    }
    .warranty{
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 0;
      p{
        position: absolute;
        width: 100%;
        top: 42px;
        text-align: center;
        ${tw`font-light text-3xl leading-none`}
        color: #444;
      }
      img{
        height: 149px;
      }
      
    }
  }
  .big-image {
    width: 100px;
    margin-right: 10px;
  }
`;

export const Pagination = styled.a`
  display: inline-block;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #222222;
  }
`;
