const url = `/.netlify/functions/getCost`;
export default async (shimBody) => {
  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(shimBody),
  }).catch((err) => {
    console.log("Get Cost Error", err);
  });
  if (res.status == 200) {
    const json = await res.json();
    //console.log({ getCostJSON: json });
    if (json) return json;
    else return false;
  } else return false;
};
