import React from "react";
import tw, { styled } from "twin.macro";

import financeIcon from "../assets/icons/fresh/icon-dollardoc-white.svg";
import installIcon from "../assets/icons/fresh/icon-installdoc.svg";

const ButtonContainer = styled.div`
  ${tw`
    flex justify-center items-center flex-row flex-wrap
    border rounded-xl p-8 mt-4 border-2
    `}
  border-color: #d1d1d1;
`;

const Button = styled.a`
  ${tw`
        flex justify-center items-center
        rounded-3xl py-2 bg-green-100
        text-white
    `}
  width: 45%;
  &.install {
    background-color: #97d43b;
  }
  &.finance {
    background-color: #1aafff;
  }
  p {
    ${tw`text-xs font-normal tracking-wider ml-1 text-white uppercase`}
  }
  img {
    margin-left: -10px;
  }
`;
const P = styled.p`
  ${tw`text-xl font-light tracking-wider text-sunobi-normal pb-6 w-full text-center`}
`;

const SideBySide = styled.div`
  ${tw`flex justify-between w-full`}
`;
export default ({
  installLink,
  fundingPartnerName,
  financeURL,
  jobCreated,
  signingCompletedFinanceDocs,
  documents,
}) => {

  const hiaDoc = documents.find((d) => d.type == "HIA");

  /*

    The Cases

    Proposal is already a job
        Only show one button: 
            The Install docs, which lets the user view their signed PDF

    Proposal is not a job 
        Cash Job: Show one button: The Install docs, which opens the signing experience in new tab
        
        Finance Job:
            Show Two buttons: 
                The Finance Docs, which opens the loanPal experience in new tab
                The installer Docs, which opens the signing experience in new tab
                
    */

  const downloadLink = "";
  return (
    <ButtonContainer>
      {jobCreated ? (
        <>
          <P>Click below to view your documents</P>
          {hiaDoc && hiaDoc.hasOwnProperty("url") ? (
            <Button href={hiaDoc.url} className="install pulse" target="_blank">
              <img src={installIcon} />
              <p>View Install Docs</p>
            </Button>
          ) : (
            <p style={{ opacity: 0.5 }}>
              Please Contact your rep for copy of docs
            </p>
          )}
        </>
      ) : (
        <>
          {fundingPartnerName == "Cash" ? (
            <>
              <P>Click below to sign your documents</P>
              {installLink && (
                <Button
                  href={installLink}
                  className="install pulse"
                  target="_blank"
                >
                  <img src={installIcon} />
                  <p>Sign Install Docs</p>
                </Button>
              )}
            </>
          ) : (
            <>
              <P>Click below to sign your documents</P>
              <SideBySide
                style={{
                  justifyContent: signingCompletedFinanceDocs
                    ? "center"
                    : "space-between",
                }}
              >
                {financeURL && !signingCompletedFinanceDocs && (
                  <Button
                    href={financeURL}
                    className="finance pulse"
                    target="_blank"
                  >
                    <img src={financeIcon} />
                    <p>Sign Finance Docs</p>
                  </Button>
                )}
                {installLink && (
                  <Button
                    href={installLink}
                    className="install pulse"
                    target="_blank"
                  >
                    <img src={installIcon} />
                    <p>Sign Install Docs</p>
                  </Button>
                )}
              </SideBySide>
            </>
          )}
        </>
      )}
    </ButtonContainer>
  );
};
