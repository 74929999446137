import tw, { styled } from "twin.macro";

export const Label = styled.p`
  ${tw`uppercase font-light text-sunobi-normal text-center tracking-widest pb-1 mt-3`}
  font-size: 0.66em;
`;

export const ThumbDiv = styled.div`
  width: 36px;
  height: 60px;
  ${tw`flex flex-col  items-center rounded-md shadow-lg border border-gray-200 pt-1 bg-white`}
  p {
    ${tw`text-base text-center text-sunobi-dark mb-2`}
  }
  img {
    width: 10px;
  }
`;

export const GraphDiv = styled.div`
  top: 480px;
  width: 960px;
  left: 50%;
  margin-left: -450px;
  ${tw`absolute flex flex-col justify-center`}

  #versusGraph .MuiSlider-thumb.Mui-focusVisible,
  #versusGraph .MuiSlider-thumb:hover {
    box-shadow: none;
  }

  #versusGraph {
    .MuiSlider-rail {
      height: 1px;
      background-color: #adadad;

      opacity: 0.22;
    }
    .MuiSlider-mark {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      margin-left: -3px;
      margin-top: -2px;
      background-color: #adadad;
      opacity: 0.5;

      &[data-index="1"],
      &[data-index="2"],
      &[data-index="3"],
      &[data-index="4"] {
        width: 3px;
        height: 3px;
        margin-left: -2px;
        margin-top: -1px;
      }
    }

    .MuiSlider-markLabel {
      top: -10px;
      ${tw`text-sm text-sunobi-normal font-light`}
      &.MuiSlider-markActive {
        background-color: #adadad;
      }
    }

    .MuiSlider-track {
      background-color: transparent;
    }
    .MuiSlider-thumb {
      width: 40px;
      box-shadow: none;
      &:focus {
        box-shadow: none;
      }
    }
    .MuiSlider-root {
      padding-bottom: 0;
      margin-bottom: 25px;
    }
  }
`;

export const YearLabels = styled.div`
  ${tw`flex`}
  margin-top: -20px;
  width: calc(100% + 15px);
  div {
    width: 4%;
    //border: 1px solid #333;
    ${tw`uppercase font-light text-sunobi-normal text-center tracking-widest`}
    font-size: 0.5em;
    &.first {
      margin-left: -15px;
    }
  }
`;
export const UtilitySidebar = styled.div`
  ${tw`absolute flex flex-col justify-center`}
  top: 450px;
  left: 0;
  p {
    ${tw`text-xs text-left font-thin tracking-widest text-sunobi-normal uppercase`}
    &.cost {
      ${tw`text-2xl text-left font-light tracking-wider`}
      color: #FF3636;
      &.big {
        ${tw`text-5xl font-light mt-2`}
      }
    }
  }
  img {
    ${tw`my-16`}
    max-width: 120px;
    max-height: 80px;
    object-fit: contain;
  }
  .heading {
    ${tw`flex items-center justify-start`}
    svg {
      width: 24px;
      margin-top: -2px;
    }
  }
`;

export const SolarSidebar = styled.div`
  ${tw`absolute flex flex-col justify-center items-end`}
  top: 450px;
  right: 0;
  .heading {
    ${tw`flex items-center justify-end`}
    svg {
      margin-right: 10px;
    }
  }
  p {
    ${tw`text-xs text-right font-thin tracking-widest text-sunobi-normal uppercase`}
    &.cost {
      ${tw`text-2xl text-right font-normal tracking-wider`}
      color: #97D33A;
      &.big {
        ${tw`text-5xl font-light mt-2`}
      }
      &.cash {
        color: #222222;
      }
      &.utility {
        ${tw`font-normal tracking-wider mt-1`}
        color: #ff0000;
        font-size: 1rem;
        line-height: 1em;
        .bubble {
          background-color: #ff0000;
          margin-left: 0.5em;
          height: 0.9em;
        }
        .bracket {
          color: #ff0000;
          font-size: 0.7em;
          position: relative;
          bottom: 2px;
        }
        .minus {
          display: inline-block;
          width: 8px;
          background-color: #777;
          height: 2px;
          position: relative;
          bottom: 4px;
          margin-left: 3px;
          margin-right: 3px;
        }
      }
      &.negative {
        color: #ff0000;
        .bubble {
          background-color: #ff0000;
        }
      }
      .bubble {
        display: inline-block;
        height: 1em;
        border-radius: 10px;
        width: 0.5em;
        background-color: #97d33a;
        margin-right: 0.25em;
        margin-bottom: -0.1em;
      }
    }
  }
  .average {
    ${tw`flex flex-col items-end mt-4`}
    .price {
      ${tw`font-bold ml-2`}
    }
  }
  .offset {
    position: relative;
    margin-right: -5px;
    ${tw`my-5 mt-7`}
    .offsetContent {
      ${tw`flex flex-col justify-center items-center text-center`}
      position: absolute;
      top: 0;

      img {
        width: 18%;
      }
      h2 {
        ${tw`text-2xl font-light leading-none mb-0 mt-1 tracking-wide`}
        color: #64A80D;
      }
      p {
        ${tw`font-normal`}
        font-size: 0.45em;
        color: #64a80d;
      }
    }

    .vertical-line {
      margin-left: -1px;
      width: 2px;
      height: 15px;
      background-color: #97d33a;
      position: absolute;
      left: 50%;
      top: 7px;
    }
    .utility-cost {
      position: absolute;
      left: 50%;
      top: -22px;
      margin-left: -6px;
      display: flex;
      p {
        ${tw`font-normal`}
        font-size: 0.9em;
        color: #ff0000;
        text-align: right;
        margin-left: 5px;
        position: relative;
        bottom: 2px;
      }
    }
  }
`;

export const SavingsArea = styled.div`
  width: 100%;
  margin-left: 20px;
  ${tw`flex items-start flex-col`}
  position: relative;
`;
export const SavingsAreaGuideLine = styled.div`
  height: 1px;
  width: 25px;
  background-color: #1ab0ff;
  top: 20px;
  left: -3px;
  position: absolute;
`;
export const SavingsAreaGuideBall = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #1ab0ff;
  position: absolute;
  top: 18px;
  left: 20px;
`;
export const SavingsAreaValue = styled.p`
  font-size: 1.5rem;
  margin: 0;
  line-height: 1em;
  color: #1ab0ff;
  padding-left: 30px;
`;

export const SavingsAreaLabel = styled.p`
  font-size: 0.75rem;
  margin: 0;
  line-height: 1em;
  color: #222222;
  text-transform: uppercase;
  padding-left: 30px;
  padding-bottom: 5px;
  font-weight: 300;
  &.marker {
    padding-left: 0;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 0;
  }
  &.smaller {
    font-size: 0.5em;
  }
`;
export const Foreign = styled.foreignObject`
  opacity: 0;
  transition: all 0.3s linear;
  &.show {
    transition: all 0.3s linear;
    opacity: 1;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const Line = styled.line`
  opacity: 0;
  transition: all 0.5s linear;
  &.show {
    transition: all 0.5s linear;
    opacity: 0.7;
  }
`;
