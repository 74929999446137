import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";

const Container = styled.div`
  width: 200px;
`;
const Bar = styled.div`
  ${tw`flex justify-between items-center`}

  height: 45px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;

  .labels {
    height: 45px;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 999;
    ${tw`flex items-center uppercase text-xs text-center  tracking-widest text-white`}
    &:first-of-type {
      padding-left: 30px;
    }
    &:last-of-type {
      padding-right: 30px;
    }
  }
`;
const Ball = styled.div`
  position: absolute;
  left: 0;
  width: 110px;
  height: 50px;
  border-radius: 30px;
  background-color: #222222;
  transform: translate(-5px);
  transition: all 0.3s linear;
  &.on {
    background-color: #ffb100;
    transform: translate(90px);
    transition: all 0.3s linear;
  }
`;

export default ({ mode, updateMode }) => {
  useEffect(() => {
    setOn(mode);
  }, [mode]);
  const [on, setOn] = useState(false);
  return (
    <>
      <Container>
        <Bar>
          <a
            className="labels"
            onClick={() => {
              if (on) {
                updateMode(false);
                setOn(false);
              }
            }}
          >
            Fossil
          </a>
          <a
            className="labels"
            onClick={() => {
              if (!on) {
                updateMode(true);
                setOn(true);
              }
            }}
          >
            Solar
          </a>
          <Ball className={on ? "on" : ""} />
        </Bar>
      </Container>
    </>
  );
};
