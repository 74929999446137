import React from "react";
import { Group } from "@visx/group";
import { LinePath, Line } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { curveLinear, curveStep } from "@visx/curve";
import { LinearGradient } from "@visx/gradient";
import { Threshold } from "@visx/threshold";
import * as S from "../styles/versus-graph-styles";
import itcIcon from "../assets/icons/systemSpecs/itc.svg";
import breakevenIcon from "../assets/icons/versus/breakeven.svg";

import formatter from "../utils/formatter";
// We'll use some mock data from `@visx/mock-data` for this.

const width = 850;
const height = 250;
// Then we'll create some bounds
const xMax = width - 110;
const yMax = height - 25;

const SavingsPolygon = ({
  graphIndex,
  previous,
  current,
  xScale,
  yScale,
  getY,
}) => {
  return current.cumulativeSavings >= previous.cumulativeSavings &&
    previous.cumulativeSavings > 0 ? (
    <>
      <polygon
        fill={"#1AB0FF"}
        style={{ opacity: 0.5 }}
        points={`
            ${xScale(graphIndex - 1)},${yScale(previous.cumulativeSavings)}
            ${xScale(graphIndex)},${yScale(current.cumulativeSavings)}
            ${xScale(graphIndex)},${yScale(
          current.cumulativeUtility
            ? (current.cumulativeUtility /
              (current.cumulativeUtility + current.cumulativeSavings)) *
            current.cumulativeSavings
            : getY(current.cumulativeUtility, "breakeven")
        )}
            ${xScale(graphIndex - 1)},${yScale(
          previous.cumulativeUtility
            ? (previous.cumulativeUtility /
              (previous.cumulativeUtility + previous.cumulativeSavings)) *
            previous.cumulativeSavings
            : getY(current.cumulativeUtility, "breakeven")
        )}
        `}
      />

      <foreignObject
        x={xScale(graphIndex - 1)}
        // Get halfway point
        y={
          /*

          This is for current versus old 
          current.before -
              (current.before -
                (current.after ? current.after : current.loanOnly)) /
                2

          */
          yScale(
            current.cumulativeSavings -
            (current.cumulativeSavings -
              (current.cumulativeUtility
                ? (previous.cumulativeUtility /
                  (previous.cumulativeUtility +
                    previous.cumulativeSavings)) *
                previous.cumulativeSavings
                : getY(current.cumulativeUtility, "breakeven"))) /
            2
          ) - 20
        }
        width="300"
        height="56"
      >
        <S.SavingsArea xmlns="http://www.w3.org/1999/xhtml">
          <S.SavingsAreaGuideLine />
          <S.SavingsAreaGuideBall />
          <S.SavingsAreaLabel>YEARS SAVINGS</S.SavingsAreaLabel>
          <S.SavingsAreaValue>
            $
            {formatter(
              current.cumulativeSavings - previous.cumulativeSavings,
              0
            )}
          </S.SavingsAreaValue>
        </S.SavingsArea>
      </foreignObject>
    </>
  ) : (
    <></>
  );
};

const getLineGradients = ({ id, color, graphIndex }) => {
  return (
    <linearGradient id={id} x1="0" y1="0" x2="1" y2="0">
      <stop offset="0" stopColor={color} stopOpacity="1" />

      <stop
        offset={(graphIndex / 25) * 100 + "%"}
        stopColor={color}
        stopOpacity="1"
      />
      <stop
        offset={(graphIndex / 25) * 100.1 + "%"}
        stopColor={color}
        stopOpacity="0.2"
      />
      <stop
        offset={((graphIndex + 1) / 25) * 100 + "%"}
        stopColor={color}
        stopOpacity="0.1"
      />
      <stop
        offset={((graphIndex + 5) / 25) * 100 + "%"}
        stopColor={color}
        stopOpacity="0"
      />
      <stop offset="100%" stopColor={color} stopOpacity="0" />
    </linearGradient>
  );
};

const TaxCreditIndicator = ({ graphIndex, current, xScale, yScale, getY }) => {
  return (
    <>
      <S.Foreign
        x={xScale(1.5) - 40}
        y={10}
        width={80}
        height={100}
        className={graphIndex > 1.5 ? "show" : ""}
      >
        <div>
          <img src={itcIcon} style={{ height: "20px" }} />
          <S.SavingsAreaLabel className="marker">18 months</S.SavingsAreaLabel>
          <S.SavingsAreaLabel className="marker smaller">
            Tax Credit
          </S.SavingsAreaLabel>
        </div>
      </S.Foreign>

      <S.Line
        x1={xScale(1.5) - 1}
        x2={xScale(1.5) - 1}
        y2={85}
        y1={180}
        stroke="#707070"
        strokeDasharray="0,12"
        strokeLinecap="round"
        className={graphIndex > 1.5 ? "show" : ""}
        style={{ strokeWidth: "3px" }}
      />
    </>
  );
};
const BreakevenIndicator = (props) => {
  const { graphData, graphIndex, xScale, yScale, getY } = props;

  for (let i = 1; i < graphData.length; i++) {
    if (
      graphData[i].cumulativeSavings > 0 &&
      graphData[i - 1].cumulativeSavings < 0
    ) {
      const delta =
        graphData[i].cumulativeSavings - graphData[i - 1].cumulativeSavings;

      return (
        <>
          <S.Foreign
            x={xScale(i) - 40}
            y={10}
            width={80}
            height={100}
            className={graphIndex > i - 1 ? "show" : ""}
          >
            <div>
              <img src={breakevenIcon} style={{ height: "20px" }} />
              <S.SavingsAreaLabel className="marker">
                {i} Years
              </S.SavingsAreaLabel>
              <S.SavingsAreaLabel className="marker smaller">
                Break Even
              </S.SavingsAreaLabel>
            </div>
          </S.Foreign>

          <S.Line
            x1={xScale(i)}
            x2={xScale(i)}
            y2={85}
            y1={180}
            stroke="#707070"
            strokeDasharray="0,12"
            strokeLinecap="round"
            className={graphIndex > i - 1 ? "show" : ""}
            style={{ strokeWidth: "3px", maxHeight: "50px" }}
          />
        </>
      );
    }
  }
  // This is a flip

  return <foreignObject></foreignObject>;
};

const CashChart = ({ graphIndex, graphData, yearlyDifference, isLease = false }) => {
  const hasUtility = graphData[0].hasOwnProperty("cumulativeUtility");
  const getX = (d, i) => i;

  const getY = (d, key) => {
    if (key == "breakeven") {
      return 0;
    }
    if (key == "cumulativeUtility") {
      // Make this propotional
      return d.cumulativeSavings > 0
        ? (d.cumulativeUtility / (d.cumulativeUtility + d.cumulativeSavings)) *
        d.cumulativeSavings
        : 0;
    }
    return d[key];
  };

  // scales
  const xScale = scaleLinear({
    range: [0, xMax],
    round: true,
    domain: [0, isLease ? 30 : 25],
  });

  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: [
      Math.min(...graphData.map((d) => d.cumulativeSavings)) - 10,
      Math.max(...graphData.map((d) => d.cumulativeSavings)) + 100,
    ],
  });

  return graphData ? (
    <div style={{ width, height, overflow: "hidden" }}>
      <svg width={width} height={height}>
        <defs>
          <linearGradient
            id="savings-gradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            gradientTransform="rotate(0)"
          >
            <stop offset="0%" stopColor="#7ac900">
              <animate
                attributeName="stopColor"
                values="#7ac900; #E3FFb9; #C1F472; #7ac900; #7ac900; #7ac900;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="50%" stopColor="#7ac900">
              <animate
                attributeName="stopColor"
                values="#7ac900; #7ac900; #E3FFb9; #C1F472; #7ac900; #7ac900;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="100%" stopColor="#7ac900">
              <animate
                attributeName="stopColor"
                values="#7ac900; #7ac900; #7ac900; #E3FFb9; #C1F472; #7ac900;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
          </linearGradient>
        </defs>
        {isLease ? <></> :
          <TaxCreditIndicator
            xScale={xScale}
            yScale={yScale}
            getY={getY}
            graphIndex={graphIndex}
          />
        }
        {isLease ? <></> :
          <BreakevenIndicator
            graphData={graphData}
            graphIndex={graphIndex}
            xScale={xScale}
            yScale={yScale}
            getY={getY}
          />
        }

        <Threshold
          id="versus-threshold"
          data={graphData.slice(0, graphIndex + 1)}
          x={(d, i) => xScale(getX(d, i))}
          y0={(d, i) => yScale(0)}
          y1={(d, i) => yScale(getY(d, "cumulativeSavings"))}
          clipBelowTo={0}
          clipAboveTo={yMax}
          curve={curveLinear}
          belowAreaProps={{
            fill: "#FF3636",
            fillOpacity: 1,
          }}
          aboveAreaProps={{
            fill: "url(#savings-gradient)",
            fillOpacity: 1,
          }}
        />
        {hasUtility && (
          <Threshold
            id="utility-threshold"
            data={graphData.slice(0, graphIndex + 1)}
            x={(d, i) => xScale(getX(d, i))}
            y1={(d, i) => yScale(getY(d, "cumulativeUtility"))}
            y0={(d, i) => yScale(0)}
            clipBelowTo={0}
            clipAboveTo={yMax}
            curve={curveLinear}
            belowAreaProps={{
              fill: "#FFA5A5",
              fillOpacity: 1,
            }}
            aboveAreaProps={{
              fill: "#FFA5A5",
              fillOpacity: 1,
            }}
          />
        )}
        {graphIndex > 0 ? (
          <>
            <SavingsPolygon
              graphIndex={graphIndex}
              previous={graphData[graphIndex - 1]}
              current={graphData[graphIndex]}
              xScale={xScale}
              yScale={yScale}
              getY={getY}
            />
          </>
        ) : (
          <></>
        )}
      </svg>
    </div>
  ) : (
    <></>
  );
};

export default CashChart;
