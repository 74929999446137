import React, {useState} from "react";
import tw, { styled } from "twin.macro";
import kotaIcon from "../assets/kota.svg";
import elementIcon from "../assets/element.png";
import empireIcon from "../assets/empire.svg";
import titanIcon from "../assets/titan.svg";

import nextPhaseIcon from "../assets/icons/np-logo.png";
import solarizeIcon from "../assets/icons/solarize-white.png";
import sunFirstIcon from "../assets/icons/sun-first-logo.png";
import urbanIcon from "../assets/icons/urban-solar-logo.png";
import milhollandIcon from "../assets/icons/milholland-logo.jpg";
import srasIcon from "../assets/icons/secure-roofing-and-solar-logo.webp";
import sunergyIcon from "../assets/icons/sunergy-logo.png";
import coreIcon from "../assets/icons/core-logo.png";
import alternativeSolarIcon from "../assets/icons/alternative-solar-logo.png";
import penIcon from "../assets/icons/pen-logo.png";
import agSolarIcon from "../assets/icons/ag-solar-logo.png";
import texasSolarIcon from "../assets/icons/texas-solar-logo.png";
import oweSolarIcon from "../assets/icons/owe.svg";
import ecovoleIcon from "../assets/icons/ecovole-logo.webp";
import nationwideIcon from "../assets/icons/nationwide-logo.png";
import lonestarIcon from "../assets/icons/lonestar-logo.png";
import ensolarIcon from "../assets/icons/ensolar-logo.png";
import fireflyIcon from "../assets/icons/firefly-logo.png";
import intyIcon from "../assets/icons/inty-logo.png";
import renuIcon from "../assets/icons/renu-logo.jpg";
import fastracIcon from "../assets/icons/fastrac-logo.png";
import fpmIcon from "../assets/icons/fpm-logo.png";
import tronIcon from "../assets/icons/tron-logo.png";
import quantumIcon from "../assets/icons/quantum-logo.png";
import willPowerIcon from "../assets/icons/will-power-logo-2.png";
import sunEnergyIcon from "../assets/icons/sun-energy-logo.png";
import ccIcon from "../assets/icons/cc-logo.png";
import greendayIcon from "../assets/icons/greenday-logo.png";
import glsIcon from "../assets/icons/green-light-logo.png";
import summitIcon from "../assets/icons/summit-logo.png";
import generationSolarIcon from "../assets/icons/generation-solar-logo.png";
import iconEnergyIcon from "../assets/icons/icon-energy-logo.png";
import infinitySolarIcon from "../assets/icons/infinity-logo.png";
import freedomSolarIcon from "../assets/icons/freedom-logo.png";
import infenergySolarIcon from "../assets/icons/infenergy-logo.png";
import elanSolarIcon from "../assets/icons/elan-solar-logo.png";
import floEnergyIcon from "../assets/icons/flo-energy-logo.png";
import equitySolarIcon from "../assets/icons/equity-solar-logo.png";
import acdcSolarIcon from "../assets/icons/acdc-solar-logo.png";
import ravenSolarIcon from "../assets/icons/raven-solar-logo.png";
import ecoHomeIcon from "../assets/icons/eco-home-logo.png";
import touchOfGreyIcon from "../assets/icons/touch-of-grey-logo.png";
import grateflSolarIcon from "../assets/icons/gratefl-solar-logo.png";
import bellSolarIcon from "../assets/icons/bell-solar-logo.png";
import sungridSolarIcon from "../assets/icons/sungrid-solar-logo.png";
import mannSolarIcon from "../assets/icons/mann-solar-logo.png";
import onPointSolarIcon from "../assets/icons/on-point-solar-logo.png";
import g3SolarIcon from "../assets/icons/g3-logo.png";
import theSolarExchangeIcon from "../assets/icons/the-solar-exchange-logo.png";
import greenstarPowerIcon from "../assets/icons/greenstar-power-logo.png";
import dawnToDuskIcon from "../assets/icons/dawn-to-dusk-logo.png";
import fusionPowerIcon from "../assets/icons/fusion-power-logo.png";
import grityPowerIcon from "../assets/icons/grity-power-logo.png";
import apolloSolarIcon from "../assets/icons/apollo-solar-logo.png";
import heroConstructionIcon from "../assets/icons/hero-construction-logo.png";
import phoenixBusinessIcon from "../assets/icons/phoenix-business-logo.png";
import kinHomeIcon from "../assets/icons/kin-home-logo.png";
import eightOneSixSolarIcon  from "../assets/icons/816-solar-pros-logo.png";
import greenBrillianceIcon  from "../assets/icons/greenbrilliance-logo.png";
import topTierSolarIcon  from "../assets/icons/top-tier-solar-logo.png";
import sunSourceIcon  from "../assets/icons/sun-source-logo.png";
import flexEnergyIcon  from "../assets/icons/flex-energy-logo.png";
import southPlainsSolarIcon  from "../assets/icons/south-plains-solar-logo.png";
import springSolarIcon  from "../assets/icons/spring-solar-logo.png";
import voltSolarEnergyIcon  from "../assets/icons/volt-solar-logo.png";
import bigDogSolarIcon  from "../assets/icons/big-dog-solar-logo.png";
import smartHomeEnergyIcon  from "../assets/icons/smart-home-energy-logo.png";
import abpElectricalIcon  from "../assets/icons/abp-electrical-logo.png";
import soleanEnergyIcon  from "../assets/icons/solean-energy-logo.png";
import convertSolarIcon  from "../assets/icons/convert-solar-logo.png";
import glydeSolarIcon  from "../assets/icons/glyde-solar-logo.png";
import solciusIcon  from "../assets/icons/solcius-logo.png";
import tempoSolarIcon  from "../assets/icons/tempo-solar-logo.png";
import actionSolarIcon  from "../assets/icons/action-solar-logo.png";
import s365IncorporatedIcon  from "../assets/icons/s365-inc-logo.png";
import titaniumSolarIcon  from "../assets/icons/titanium-solar-logo.png";

import kotaBackground from "../assets/kota-background.jpg";
import elementBackground from "../assets/element-background.jpg";
import empireBackground from "../assets/empire-background.jpg";
import titanBackground from "../assets/titan-background.jpg";
import npBackground from "../assets/icons/np-bg.webp";
import milhollandBackground from "../assets/icons/milholland-bg.webp";
import coreBackground from "../assets/icons/core-bg3.png";
import alternativeSolarBackground from "../assets/icons/alternative-solar-bg2.jpeg";
import penBackground from "../assets/icons/pen-bg1.jpeg";
import agSolarBackground from "../assets/icons/ag-solar-bg1.jpeg";
import texasSolarBackground from "../assets/icons/texas-solar-bg1.jpg";
import ecovoleBackground from "../assets/icons/ecovole-bg.jpg";
import lonestarBackground from "../assets/icons/lonestar-bg.jpg";
import ensolarBackground from "../assets/icons/ensolar-bg.jpg";
import headlineBackground from "../assets/icons/headline-bg.jpg";
import fireflyBackground from "../assets/icons/firefly-bg.jpg";
import intyBackground from "../assets/icons/inty-bg.jpg";
import fpmBackground from "../assets/icons/fpm-bg.jpg";
import quantumBackground from "../assets/icons/quantum-bg.jpg";
import willPowerBackground from "../assets/icons/will-power-bg.jpg";
import sunEnergyBackground from "../assets/icons/sun-energy-bg.jpeg";
import ccBackground from "../assets/icons/cc-background.png";
import greendayBackground from "../assets/icons/greenday-bg.jpg";
import summitBackground from "../assets/icons/summit-bg.jpg";
import generationSolarBackground from "../assets/icons/generation-solar-bg.jpg";
import infenergySolarBackground from "../assets/icons/infenergy-bg.jpg";
import iconEnergyBackground from "../assets/icons/icon-energy-bg.jpg";
import elanSolarBackground from "../assets/icons/elan-solar-bg.jpg";
import floEnergyBackground from "../assets/icons/flo-energy-bg.jpeg";
import equitySolarBackground from "../assets/icons/equity-solar-bg.png";
import acdcSolarBackground from "../assets/icons/acdc-solar-bg.jpeg";
import ravenSolarBackground from "../assets/icons/raven-solar-bg.jpeg";
import ecoHomeBackground from "../assets/icons/eco-home-bg.jpeg";
import touchOfGreyBackground from "../assets/icons/touch-of-grey-bg.jpeg";
import grateflSolarBackground from "../assets/icons/gratefl-solar-bg.jpeg";
import bellSolarBackground from "../assets/icons/bell-solar-bg.jpeg";
import sungridSolarBackground from "../assets/icons/sungrid-solar-bg.png";
import mannSolarBackground from "../assets/icons/mann-solar-bg.jpeg";
import onPointSolarBackground from "../assets/icons/on-point-solar-bg.jpeg";
import g3SolarBackground from "../assets/icons/g3-solar-bg.jpeg";
import g3SolarBackground2 from "../assets/icons/g3-solar-bg-2.jpeg";
import theSolarExchangeBackground from "../assets/icons/the-solar-exchange-bg.jpeg";
import greenstarPowerBackground from "../assets/icons/greenstar-power-bg.jpeg";
import dawnToDuskBackground from "../assets/icons/dawn-to-dusk-bg.jpeg";
import fusionPowerBackground from "../assets/icons/fusion-power-bg.jpeg";
import grityPowerBackground from "../assets/icons/grity-power-bg.jpeg";
import apolloSolarBackground from "../assets/icons/apollo-solar-bg.jpeg";
import heroConstructionBackground from "../assets/icons/hero-construction-bg.jpeg";
import phoenixBusinessBackground from "../assets/icons/phoenix-business-bg.jpeg";
import kinHomeBackground from "../assets/icons/kin-home-bg.jpeg";
import eightOneSixSolarBackground  from "../assets/icons/816-solar-pros-bg.png";
import greenBrillianceBackground  from "../assets/icons/greenbrilliance-bg.jpeg";
import topTierSolarBackground  from "../assets/icons/top-tier-solar-bg.jpeg";
import sunSourceBackground  from "../assets/icons/sun-source-bg.jpeg";
import southPlainsSolarBackground  from "../assets/icons/south-plains-solar-bg.jpeg";
import springSolarBackground  from "../assets/icons/spring-solar-bg.jpeg";
import voltSolarEnergyBackground  from "../assets/icons/volt-solar-bg.jpeg";
import bigDogSolarBackground  from "../assets/icons/big-dog-solar-bg.jpeg";
import smartHomeEnergyBackground  from "../assets/icons/smart-home-energy-bg.jpeg";
import abpElectricalBackground  from "../assets/icons/abp-electrical-bg.jpeg";
import soleanEnergyBackground  from "../assets/icons/solean-energy-bg.jpeg";
import convertSolarBackground  from "../assets/icons/convert-solar-bg.jpeg";
import glydeSolarBackground  from "../assets/icons/glyde-solar-bg.jpeg";
import solciusBackground  from "../assets/icons/solcius-bg.jpeg";
import tempoSolarBackground  from "../assets/icons/tempo-solar-bg.jpg";
import actionSolarBackground  from "../assets/icons/action-solar-bg.jpg";
import s365IncorporatedBackground  from "../assets/icons/s365-inc-bg.jpeg";
import titaniumSolarBackground  from "../assets/icons/titanium-solar-bg.jpg";

const Title = tw.h1 `
    text-2xl uppercase text-center text-white font-thin tracking-wider pb-1
`;
const SubTitle = tw.h2 `
    text-lg uppercase text-center text-white font-thin tracking-wider
`;

const Background = styled.div `
  min-height: 800px;
  ${tw`w-screen bg-cover flex items-center justify-center relative`}
`;

const BackgroundTint = styled.div `
  min-height: 800px;
  ${tw`w-screen bg-cover flex items-center justify-center relative`}
`;

const Gradient = styled.div`
  min-height: 800px;
  ${tw`absolute w-screen bg-cover`}
`;

const Container = tw.div`
    flex items-center justify-between flex-col
`;

const Intro = tw.div`
    absolute bottom-0 pb-5 md:pb-20 
`;

const FirstLogo = tw.img`
    w-auto
    h-auto
`;

const PoweredBy = tw.h3 `
    text-xl uppercase text-center text-white font-light tracking-wider
`;

// const SecondLogo = tw.img`
//     w-5/12
// `;

const SecondLogo = tw.img`
    h-full
    w-auto
`;

// Magic numbers but mergh
const dynamicAssets = (id) => {

  if (id == 86) {
    // Titanium Solar
    return {
      icon: titaniumSolarIcon,
      back: titaniumSolarBackground,
      opac: 0,
      style: { 
        width: "30%"
        //,filter: "drop-shadow(2px 2px 0px white)"
      },
    };
  }

  if (id == 85) {
    // S365 Incorporated
    return {
      icon: s365IncorporatedIcon,
      back: s365IncorporatedBackground,
      opac: 0.1,
      style: { 
        width: "22%"
        //,filter: "drop-shadow(2px 2px 0px white)"
      },
    };
  }


  if (id == 83) {
    // Action Solar
    return {
      icon: actionSolarIcon,
      back: actionSolarBackground,
      opac: 0.3,
      style: { 
        width: "28%"
        //,filter: "drop-shadow(2px 2px 0px white)"
      },
    };
  }


  if (id == 82) {
    // Tempo Solar
    return {
      icon: tempoSolarIcon,
      back: tempoSolarBackground,
      opac: 0,
      style: { 
        width: "75%",
        paddingBottom:"10%"
      },
    };
  }

  if (id == 80) {
    // Solcius, LLC
    return {
      icon: solciusIcon,
      back: solciusBackground,
      opac: 0.6,
      style: { 
        width: "50%",
        paddingBottom:"10%"
      },
    };
  }

  if (id == 79) {
    // Glyde Solar
    return {
      icon: glydeSolarIcon,
      back: glydeSolarBackground,
      opac: 0.2,
      style: { 
        width: "30%"
      },
    };
  }

  if (id == 78) {
    // Convert Solar
    return {
      icon: convertSolarIcon,
      back: convertSolarBackground,
      opac: 0,
      style: { 
        width: "30%",
        paddingBottom:"12%"
      },
    };
  }

  if (id == 77) {
    // Solean Energy
    return {
      icon: soleanEnergyIcon,
      back: soleanEnergyBackground,
      opac: 0,
      style: { 
        width: "38%"
      },
    };
  }

  if (id == 76) {
    // ABP Electrical Systems, Inc.
    return {
      icon: abpElectricalIcon,
      back: abpElectricalBackground,
      opac: 0,
      style: { 
        width: "100%",
        paddingBottom: "35%"
      },
    };
  }

  if (id == 75) {
    // Smart Home Energy Source, LLC
    return {
      icon: smartHomeEnergyIcon,
      back: smartHomeEnergyBackground,
      opac: 0.4,
      style: { 
        width: "30%"
      },
    };
  }

  if (id == 74) {
    // Big Dog Solar
    return {
      icon: bigDogSolarIcon,
      back: bigDogSolarBackground,
      opac: 0.4,
      style: { 
        width: "90%"
      },
    };
  }

  if (id == 73) {
    // Volt Solar Energy
    return {
      icon: voltSolarEnergyIcon,
      back: voltSolarEnergyBackground,
      opac: 0.5,
      style: { 
        width: "30%"
      },
    };
  }
  
  if (id == 72) {
    // Spring Solar, LLC
    return {
      icon: springSolarIcon,
      back: acdcSolarBackground,
      opac: 0,
      style: { 
        width: "50%",
        paddingBottom: "10%"
      },
    };
  }

  if (id == 71) {
    // South Plains Solar
    return {
      icon: southPlainsSolarIcon,
      back: southPlainsSolarBackground,
      opac: 0,
      style: { 
        width: "50%",
        paddingBottom: "45%"
      },
    };
  }
  
  if (id == 70) {
    // Flex Energy
    return {
      icon: flexEnergyIcon,
      back: topTierSolarBackground,
      opac: 0,
      style: { 
        width: "40%",
        paddingBottom: "10%"
      },
    };
  }
  
  if (id == 69) {
    // Sun Source Installations
    return {
      icon: sunSourceIcon,
      back: sunSourceBackground,
      opac: 0,
      style: { 
        width: "30%",
        paddingBottom: "10%"
      },
    };
  }
  
  if (id == 68) {
    // Top Tier Solar Solutions
    return {
      icon: topTierSolarIcon,
      back: topTierSolarBackground,
      opac: 0.25,
      style: { 
        width: "60%"
      },
    };
  }
  
  if (id == 67) {
    // GreenBrilliance, LLC
    return {
      icon: greenBrillianceIcon,
      back: greenBrillianceBackground,
      opac: 0,
      style: { 
        width: "40%",
        paddingBottom: "20%"
      },
    };
  }
  
  if (id == 66) {
    // 816 Solar Pros
    return {
      icon: eightOneSixSolarIcon,
      back: eightOneSixSolarBackground,
      opac: 0.3,
      style: { 
        width: "40%"
      },
    };
  }
  
  if (id == 65) {
    // Phoenix Business Solutions, LLC
    return {
      icon: phoenixBusinessIcon,
      back: phoenixBusinessBackground,
      opac: 0.4,
      style: { 
        width: "40%"
      },
    };
  }

  if (id == 63) {
    // Hero Construction Group, Inc.
    return {
      icon: heroConstructionIcon,
      back: heroConstructionBackground,
      opac: 0.2,
      style: { 
        width: "40%"
      },
    };
  }
  
  if (id == 62) {
    // Apollo Solar Energy and Investments, LLC
    return {
      icon: apolloSolarIcon,
      back: apolloSolarBackground,
      opac: 0.15,
      style: { 
        width: "30%"
      },
    };
  }

  if (id == 61) {
    // Grity Power, LLC
    return {
      icon: grityPowerIcon,
      back: grityPowerBackground,
      opac: 0.15,
      style: { 
        width: "30%"
      },
    };
  }
  
  if (id == 60) {
    // Fusion Power
    return {
      icon: fusionPowerIcon,
      back: fusionPowerBackground,
      opac: 0.25,
      style: { 
        width: "30%"
      },
    };
  }

  if (id == 59) {
    // Dawn to Dusk Solar Texas, LLC
    return {
      icon: dawnToDuskIcon,
      back: dawnToDuskBackground,
      opac: 0.1,
      style: { 
        width: "18%"
      },
    };
  }
  
  if (id == 58) {
    // Greenstar Power, LLC
    return {
      icon: greenstarPowerIcon,
      back: greenstarPowerBackground,
      opac: 0.2,
      style: { 
        width: "40%",
        paddingBottom: "15%"
      },
    };
  }

  if (id == 57) {
    // The Solar Exchange
    return {
      icon: theSolarExchangeIcon,
      back: theSolarExchangeBackground,
      opac: 0,
      style: { 
        width: "100%",
        paddingBottom: "45%"
      },
    };
  }

  if (id == 56) {
    // G3 Solar
    return {
      icon: g3SolarIcon,
      back: g3SolarBackground,
      opac: 0.1,
      style: { 
        width: "40%",
        paddingBottom: "45%"
      },
    };
  }

  if (id == 55) {
    // On Point Solar Power
    return {
      icon: onPointSolarIcon,
      back: onPointSolarBackground,
      opac: 0.2,
      style: { 
        width: "35%"
      },
    };
  }

  
  if (id == 54) {
    // Mann Solar
    return {
      icon: mannSolarIcon,
      back: mannSolarBackground,
      opac: 0.2,
      style: { 
        width: "40%"
      },
    };
  }

  if (id == 53) {
    // SunGrid Solar
    return {
      icon: sungridSolarIcon,
      back: sungridSolarBackground,
      opac: 0.4,
      style: { 
        width: "40%"
      },
    };
  }

  if (id == 52) {
    // Bell Solar
    return {
      icon: bellSolarIcon,
      back: bellSolarBackground,
      opac: 0.1,
      style: { 
        width: "40%",
        paddingBottom: "5%"
      },
    };
  }


  if (id == 51) {
    // Gr8fl Solar
    return {
      icon: grateflSolarIcon,
      back: touchOfGreyBackground,
      opac: 0.1,
      style: { 
        width: "40%",
        paddingBottom: "17%"
      },
    };
  }
  
  if (id == 50) {
    // Touch of Grey Solar
    return {
      icon: touchOfGreyIcon,
      back: touchOfGreyBackground,
      opac: 0.1,
      style: { 
        width: "40%",
        paddingBottom: "25%"
      },
    };
  }
  
  if (id == 49) {
    // ECO Home Inc
    return {
      icon: ecoHomeIcon,
      back: ecoHomeBackground,
      opac: 0.3,
      style: { 
        width: "30%"
      },
    };
  }
  
  if (id == 48) {
    // Raven Solar Services, LLC
    return {
      icon: ravenSolarIcon,
      back: ravenSolarBackground,
      opac: 0.2,
      style: { 
        width: "50%",
        marginBottom: "50%"
      },
    };
  }
  
  if (id == 47) {
    // AC/DC Solar, LLC
    return {
      icon: acdcSolarIcon,
      back: acdcSolarBackground,
      opac: 0.2,
      style: { 
        width: "20%",
        marginBottom: "20%"
      },
    };
  }
  
  if (id == 46) {
    // Equity Solar
    return {
      icon: equitySolarIcon,
      back: equitySolarBackground,
      opac: 0.1,
      style: { 
        width: "14%",
        marginBottom: "30%"
      },
    };
  }
  
  if (id == 45) {
    // Elan Solar
    return {
      icon: elanSolarIcon,
      back: elanSolarBackground,
      opac: 0.1,
      style: { 
        width: "30%",
        marginBottom: "85%"
      },
    };
  }

  if (id == 44) {
    // Flo Energy LLC
    return {
      icon: floEnergyIcon,
      back: generationSolarBackground,
      opac: 0.1,
      style: { 
        width: "30%",
        marginBottom: "20%"
      },
    };
  }

  if (id == 43) {
    // Kin Home, formally known as Icon Energy
    return {
      icon: kinHomeIcon,
      back: kinHomeBackground,
      opac: 0.1,
      style: { 
        width: "30%",
        marginBottom: "30%",
        marginLeft: "50%"
      },
    };
  }

  if (id == 42) {
    // Generation Solar
    return {
      icon: generationSolarIcon,
      back: generationSolarBackground,
      opac: 0.1,
      style: { 
        width: "80%",
      },
    };
  }

  if (id == 41) {
    // Infinity Solar Systems
    return {
      icon: infinitySolarIcon,
      back: elementBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 40) {
    // Freedom Solar Energy
    return {
      icon: freedomSolarIcon,
      back: elementBackground,
      opac: 0.1,
      style: { 
        width: "25%",
      },
    };
  }

  if (id == 39) {
    // Infenergy Solar Solutions
    return {
      icon: infenergySolarIcon,
      back: infenergySolarBackground,
      opac: 0.1,
      style: { 
        width: "25%",
      },
    };
  }

  if (id == 38) {
    // Green Day Power
    return {
      icon: greendayIcon,
      back: greendayBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 37) {
    // Cornerstone Construction
    return {
      icon: ccIcon,
      back: ccBackground,
      opac: 0.1,
      style: { 
        width: "100%",
        backgroundColor: "rgba(255,255,255,0.5)",
        borderRadius: "10px"
      },
    };
  }

  if (id == 36) {
    // Summit Energy Group
    return {
      icon: summitIcon,
      back: summitBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 35) {
    // Green Light Solar
    return {
      icon: glsIcon,
      back: elementBackground,
      opac: 0.1,
      style: { 
        width: "75%",
      },
    };
  }

  if (id == 34) {
    // Sun Energy
    return {
      icon: sunEnergyIcon,
      back: sunEnergyBackground,
      opac: 0.1,
      style: { 
        width: "50%",
        backgroundColor: "rgba(255,255,255,0.5)",
        borderRadius: "10px"
      },
    };
  }

  if (id == 33) {
    // Will Power
    return {
      icon: willPowerIcon,
      back: willPowerBackground,
      opac: 0.1,
      style: { 
        width: "100%",
        backgroundColor: "rgba(255,255,255,0.85)"
      },
    };
  }

  if (id == 32) {
    // Quantum
    return {
      icon: quantumIcon,
      back: quantumBackground,
      opac: 0.1,
      style: { 
        width: "50%"
      },
    };
  }

  if (id == 31) {
    // TRON Solar
    return {
      icon: tronIcon,
      back: fpmBackground,
      opac: 0.1,
      style: { 
        width: "50%",
        backgroundColor: "rgba(255,255,255,0.85)"
      },
    };
  }

  if (id == 30) {
    // Florida Power Management
    return {
      icon: fpmIcon,
      back: penBackground,
      opac: 0.1,
      style: { 
        width: "40%",
      },
    };
  }

  if (id == 29) {
    // Fastrac
    return {
      icon: fastracIcon,
      back: elementBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 28) {
    // INTY
    return {
      icon: intyIcon,
      back: intyBackground,
      opac: 0.1,
      style: { 
        width: "40%",
      },
    };
  }

  if (id == 27) {
    // ReNu
    return {
      icon: renuIcon,
      back: milhollandBackground,
      opac: 0.1,
      style: { 
      },
    };
  }

  if (id == 26) {
    // Firefly
    return {
      icon: fireflyIcon,
      back: fireflyBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 25) {
    // Headline
    return {
      icon: "",
      back: headlineBackground,
      opac: 0.1,
    };
  }

  if (id == 24) {
    // Ensolar
    return {
      icon: ensolarIcon,
      back: ensolarBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 23) {
    // Lonestar
    return {
      icon: lonestarIcon,
      back: lonestarBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 22) {
    // Nationwide
    return {
      icon: nationwideIcon,
      back: npBackground,
      opac: 0.1,
    };
  }

  if (id == 21) {
    // Ecovole
    return {
      icon: ecovoleIcon,
      back: ecovoleBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 20) {
    // Our World Energy
    return {
      icon: oweSolarIcon,
      back: texasSolarBackground,
      opac: 0.1,
      style: { 
        width: "30%",
      },
    };
  }

  if (id == 19) {
    // Texas Solar
    return {
      icon: texasSolarIcon,
      back: texasSolarBackground,
      opac: 0.1,
    };
  }

  if (id == 18) {
    // AgSolar
    return {
      icon: agSolarIcon,
      back: agSolarBackground,
      opac: 0.1,
      style: { 
        width: "30%",
      },
    };
  }

  if (id == 17) {
    // Pacific Energy Network
    return {
      icon: penIcon,
      back: penBackground,
      opac: 0.1,
      style: { 
        width: "30%",
      },
    };
  }

  if (id == 15) {
    // Alternative Solar
    return {
      icon: alternativeSolarIcon,
      back: alternativeSolarBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 14) {
    // Sunnergy
    return {
      icon: sunergyIcon,
      back: elementBackground,
      opac: 0.1,
      style: { 
        width: "50%",
      },
    };
  }
  
  if (id == 13) {
    // Secure roofing & solar
    return {
      icon: srasIcon,
      back: titanBackground,
      opac: 0.1,
    };
  }

  if (id == 12) {
    // Milholland
    return {
      icon: milhollandIcon,
      back: milhollandBackground,
      opac: 0.1,
    };
  }

  if (id == 10) {
    // Urban
    return {
      icon: urbanIcon,
      back: titanBackground,
      opac: 0.1,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 9) {
    // Next Phase
    return {
      icon: nextPhaseIcon,
      back: npBackground,
      opac: 0.1,
      style: { 
        width: "70%",
      },
    };
  }

  if (id == 8) {
    // Sun First
    return {
      icon: sunFirstIcon,
      back: titanBackground,
      opac: 0.1,
    };
  }

  if (id == 7) {
    // Solarize
    return {
      icon: solarizeIcon,
      back: elementBackground,
      opac: 0,
      style: { 
        width: "70%",
      },
    };
  }

  if (id == 6) {
    // core
    return {
      icon: coreIcon,
      back: coreBackground,
      opac: 0.3,
      style: { 
        width: "100%",
      },
    };
  }

  if (id == 5) {
    // element
    return {
      icon: elementIcon,
      back: elementBackground,
      opac: 0.3,
    };
  }
  if (id == 3) {
    // Empire
    return {
      icon: empireIcon,
      back: empireBackground,
      opac: 0.3,
    };
  }
  if (id == 2) {
    // Titan
    return {
      icon: titanIcon,
      back: titanBackground,
      opac: 0.3,
    };
  }
  return {
    icon: kotaIcon,
    back: kotaBackground,
    opac: 0.2,
  };
};

//!!!!!  This is the response we gonna get from the server !!!!!//

// type customerProposalCustomisations struct {
// 	SalesOrganisationLogoURL   string  `json:"salesOrganisationLogoURL"` - done
// 	SalesOrganisationLogoScale float32 `json:"salesOrganisationLogoScale"` - done
// 	SalesOrganisationName      string  `json:"salesOrganisationName"`
// 	InstallerLogoURL           string  `json:"installerLogoURL"` - done
// 	InstallerLogoScale         float32 `json:"installerLogoScale"` - done
// 	InstallerName              string  `json:"installerName"` - done
// 	BackgroundImageURL         string  `json:"backgroundImageURL"` - done
// 	BackgroundTintOpacity      float32 `json:"backgroundTintOpacity"` - done
// 	InstallerFirst             bool    `json:"installerFirst"` - done
// 	FirstOnly                  bool    `json:"firstOnly"` - done
// 	Statement                  string  `json:"statement"` - done
//  LaborWarrantyTerm           int     `json:"laborWarrantyTerm"`
//  RoofPenetrationWarrantyTerm int     `json:"roofPenetrationWarrantyTerm"`
//  ManufacturersWarrantyTerm   int     `json:"manufacturersWarrantyTerm"`
//  PerformanceGuaranteeTerm    int     `json:"performanceGuaranteeTerm"`
// }

//!!!!! End response !!!!!//


const Hero = ({ installerRecordID, leadName, leadFullAddress, customisations, loadingComplete }) => {
  const assets = dynamicAssets(installerRecordID);

  // If sales logo URL fails, set it to null
  const [salesImage, setSalesImage] = useState(customisations.salesOrganisationLogoURL);
  const handleSalesImageFail = () => {setSalesImage(null)};

  // If installer logo URL fails, set it to null
  const [installerImage, setInstallerImage] = useState(customisations.installerLogoURL);
  const handleInstallerImageFail = () => {setInstallerImage(null)};

  // If background image URL fails, set it to KOTA's background
  const [backgroundImageURL, setBackgroundImageURL] = useState(customisations?.backgroundImageURL);

  // Did the sales logo URL work?
  const salesLogoExists = salesImage > "" ? true : false;
  
  // If a background image exists in Supabase, use it, otherwise fallback to KOTA's background
  const backgroundImage = backgroundImageURL > "" ? backgroundImageURL : "https://aavnazvqcleiwnypjkrk.supabase.co/storage/v1/object/public/installer-backgrounds/4.jpg";

  // Show sales first if the logo exists AND installer doesn't want to be first
  const showSalesFirst = salesLogoExists && customisations.installerFirst === false ? true : false;

  // Logo scale and border styling
  const installerLogoStyle = customisations.installerLogoBorder ? {display:"block", maxWidth:"100%", maxHeight:"fill-available", transform: `scale(${customisations.installerLogoScale})`, filter: `drop-shadow(1px 1px 0.5px ${customisations.installerLogoBorderColor}) drop-shadow(-1px -1px 0.5px ${customisations.installerLogoBorderColor}) drop-shadow(-1px 1px 0.5px ${customisations.installerLogoBorderColor}) drop-shadow(1px -1px 0.5px ${customisations.installerLogoBorderColor})`} : {maxWidth:"100%", transform: `scale(${customisations.installerLogoScale})`};
  const salesOrganisationLogoStyle = customisations.salesOrganisationLogoBorder ? {maxWidth:"100%", transform: `scale(${customisations.salesOrganisationLogoScale})`, filter: `drop-shadow(1px 1px 0.5px ${customisations.salesOrganisationLogoBorderColor}) drop-shadow(-1px -1px 0.5px ${customisations.salesOrganisationLogoBorderColor}) drop-shadow(-1px 1px 0.5px ${customisations.salesOrganisationLogoBorderColor}) drop-shadow(1px -1px 0.5px ${customisations.salesOrganisationLogoBorderColor})`} : { maxWidth:"100%", transform: `scale(${customisations.salesOrganisationLogoScale})`};
  
  // Toggle first and second URLs
  const firstLogoURL = showSalesFirst ? salesImage : installerImage;
  const secondLogoURL = showSalesFirst ? installerImage : salesImage;

  //Show second section
  const showSecondSection = secondLogoURL === null ? false : true;

  // Toggle container styling based on existence of second section
  const outerContainerStyle = showSecondSection ? {display:"flex", height:"100%"} : {display:"flex", height:"600px", alignSelf:"flex-end"};
  const topContainerStyle = showSecondSection ? {height:"400px", maxWidth:"500px", paddingTop:"75px", justifyContent:"end"} : {height:"100%", width:"auto", maxWidth:"500px", justifyContent:"center"};

  return (
    !loadingComplete ? null : 
    <Background
      className="min-w-1280 hero-height"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <BackgroundTint
        className="hero-height min-w-1280"
        style={{ backgroundColor: `rgba(0,0,0,${customisations.backgroundTintOpacity})`, maxHeight: `90vh` }}
      >
        <Gradient 
          className="hero-gradient min-w-1280 hero-height"
          style={{ height: `100%`}}>
        </Gradient>
        <Container style={outerContainerStyle}>
          <Container style={topContainerStyle}>
            <FirstLogo
              id="first-logo"
              src={firstLogoURL}
              onError={showSalesFirst ? handleSalesImageFail: handleInstallerImageFail}
              style={showSalesFirst === false ? installerLogoStyle : salesOrganisationLogoStyle}
            />
          </Container>
          <Container className="container" style={{height:`${showSecondSection ? "400px" : "340px"}`}}>
          { !showSecondSection ? null : 
            <Container className="container" style={{paddingTop:"70px"}}>
              <PoweredBy 
                style={{filter:"drop-shadow(1px 1px 0.5px black) drop-shadow(-1px -1px 0.5px black) drop-shadow(-1px 1px 0.5px black) drop-shadow(1px -1px 0.5px black)"}}
              >
                {/* {customisations.statement} */}
                {showSalesFirst ? "INSTALLED BY" : "PARTNERED WITH"}
              </PoweredBy>
              <Container id="second-logo-wrapper-1">
                <Container id="second-logo-wrapper-2">
                  <img src={secondLogoURL} id="second-logo"
                    onError={showSalesFirst ? handleInstallerImageFail : handleSalesImageFail}
                    style={showSalesFirst ? installerLogoStyle : salesOrganisationLogoStyle} 
                  />
                  </Container>
              </Container>
            </Container>
          }
            <Intro>
              <Title>Your solar energy report</Title>
              <SubTitle>
                {leadName} :{" "}
                <span style={{ opacity: 0.7 }}>{leadFullAddress}</span>
              </SubTitle>
            </Intro>
          </Container>
        </Container>
      </BackgroundTint>
    </Background>
  );
};

export default Hero;