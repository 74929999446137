import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";

const Container = styled.div`
  position: relative;
  width: 40px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  height: 20px;
`;
const Bar = styled.div`
  position: absolute;
  top: 2px;
  width: 30px;
  height: 10px;
  background-color: #cecece;
  border-radius: 15px;
  transition: all 0.1s linear 0.2s;
  cursor: pointer;
`;
const Ball = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.25);
  transform: translate(-3px);
  transition: all 0.3s linear;
  &.middle {
    transform: translate(7.5px);
    transition: all 0.3s linear;
  }
  &.right {
    transform: translate(20px);
    transition: all 0.3s linear;
  }
`;

export default ({ value, mode = "left" }) => {
  return (
    <>
      <Container>
        <Bar />
        <Ball className={mode}></Ball>
      </Container>
    </>
  );
};
