import React from "react";
import { Group } from "@visx/group";
import { LinePath } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { curveLinear, curveStep } from "@visx/curve";
import { LinearGradient } from "@visx/gradient";
import { Threshold } from "@visx/threshold";
import * as S from "../styles/versus-graph-styles";

import formatter from "../utils/formatter";
// We'll use some mock data from `@visx/mock-data` for this.

const width = 850;
const height = 250;
// Then we'll create some bounds
const xMax = width - 110;
const yMax = height - 25;

const SavingsPolygon = ({
  graphIndex,
  previous,
  current,
  savings,
  xScale,
  yScale,
}) => {
  return previous.before >=
    (previous.after ? previous.after : previous.loanOnly) ? (
    <>
      <polygon
        fill={"#1AB0FF"}
        style={{ opacity: 0.5 }}
        points={`
            ${xScale(graphIndex - 1)},${yScale(previous.before)}
            ${xScale(graphIndex)},${yScale(current.before)}
            ${xScale(graphIndex)},${yScale(
          current.after ? current.after : current.loanOnly
        )}
            ${xScale(graphIndex - 1)},${yScale(
          previous.after ? previous.after : previous.loanOnly
        )}
        `}
      />
      <foreignObject
        x={xScale(graphIndex - 1)}
        // Get halfway point
        y={
          yScale(
            current.before -
            (current.before -
              (current.after ? current.after : current.loanOnly)) /
            2
          ) - 20
        }
        width="300"
        height="56"
      >
        <S.SavingsArea>
          <S.SavingsAreaGuideLine />
          <S.SavingsAreaGuideBall />
          <S.SavingsAreaLabel>YEARS SAVINGS</S.SavingsAreaLabel>
          <S.SavingsAreaValue>${formatter(savings, 0)}</S.SavingsAreaValue>
        </S.SavingsArea>
      </foreignObject>
    </>
  ) : (
    <></>
  );
};

const Markers = ({
  graphIndex,
  previous,
  current,
  savings,
  xScale,
  yScale,
}) => {
  return (
    <>
      <foreignObject
        x={xScale(graphIndex) + 5}
        y={yScale(current.before) - 12}
        width="24"
        height="24"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23.977"
          height="23.977"
          viewBox="0 0 23.977 23.977"
        >
          <path
            id="Path_5738"
            data-name="Path 5738"
            d="M-4.008-4.008,9.883-7.071,6.82,6.82-7.071,9.883Z"
            transform="translate(10 11.988) rotate(-45)"
            fill="#ff3636"
          />
        </svg>
      </foreignObject>

      <foreignObject
        x={xScale(graphIndex) + 8}
        y={
          yScale(
            current.after
              ? (current.after + current.loanOnly) / 2
              : current.loanOnly
          ) - 10
        }
        width="19"
        height="19"
      >
        <svg
          id="Component_26_1"
          data-name="Component 26 – 1"
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
        >
          <rect
            id="Rectangle_4112"
            data-name="Rectangle 4112"
            width="19"
            height="19"
            rx="9.5"
            fill="#97d33a"
          />
          <g
            id="Rectangle_4113"
            data-name="Rectangle 4113"
            transform="translate(6 6)"
            fill="red"
            stroke="#fff"
            strokeWidth="1"
          >
            {current.after && (
              <>
                <rect width="7" height="7" rx="3.5" stroke="none" />
                <rect
                  x="-0.5"
                  y="-0.5"
                  width="8"
                  height="8"
                  rx="4"
                  fill="none"
                />
              </>
            )}
          </g>
        </svg>
      </foreignObject>
    </>
  );
};

const getLineGradients = ({ id, color, graphIndex, total }) => {
  return (
    <linearGradient id={id} x1="0" y1="0" x2="1" y2="0">
      <stop offset="0" stopColor={color} stopOpacity="1" />

      <stop
        offset={(graphIndex / total) * 100 + "%"}
        stopColor={color}
        stopOpacity="1"
      />
      <stop
        offset={(graphIndex / total) * 100.1 + "%"}
        stopColor={color}
        stopOpacity="0.2"
      />
      <stop
        offset={((graphIndex + 1) / total) * 100 + "%"}
        stopColor={color}
        stopOpacity="0.1"
      />
      <stop
        offset={((graphIndex + 5) / total) * 100 + "%"}
        stopColor={color}
        stopOpacity="0"
      />
      <stop offset="100%" stopColor={color} stopOpacity="0" />
    </linearGradient>
  );
};

const LoanChart = ({
  graphIndex,
  graphData,
  yearlyDifference,
  fullSavingsTerm,
}) => {
  const hasAfter = graphData[0].hasOwnProperty("after");

  // data accessors
  const getX = (d, i) => i;

  const getY = (d, i, key) => {
    if (i == fullSavingsTerm && key == "loanOnly") {
      return d[key] * 1.05;
    }
    return d[key];
  };

  // scales
  const xScale = scaleLinear({
    range: [0, xMax],
    round: true,
    domain: [0, fullSavingsTerm],
  });

  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: [
      Math.min(
        ...graphData.map((d) => d.before),
        ...graphData.map((d) => d.loanOnly)
      ) - 10,
      Math.max(
        ...graphData.map((d) => d.before),
        ...graphData.map((d) => d.loanOnly)
      ) + 100,
    ],
  });

  return graphData ? (
    <div style={{ width, height, overflow: "hidden" }}>
      <svg width={width} height={height}>
        <defs>
          {getLineGradients({
            id: "beforeGrad",
            graphIndex,
            color: "#FF3636",
            total: fullSavingsTerm,
          })}
          {getLineGradients({
            id: "loanGrad",
            graphIndex,
            color: "#97D33A",
            total: fullSavingsTerm,
          })}
          {getLineGradients({
            id: "afterGrad",
            graphIndex,
            color: "#FFA5A5",
            total: fullSavingsTerm,
          })}

          <linearGradient
            id="savings-gradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            gradientTransform="rotate(0)"
          >
            <stop offset="0%" stopColor="#7ac900">
              <animate
                attributeName="stopColor"
                values="#7ac900; #E3FFb9; #C1F472; #7ac900; #7ac900; #7ac900;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="50%" stopColor="#7ac900">
              <animate
                attributeName="stopColor"
                values="#7ac900; #7ac900; #E3FFb9; #C1F472; #7ac900; #7ac900;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="100%" stopColor="#7ac900">
              <animate
                attributeName="stopColor"
                values="#7ac900; #7ac900; #7ac900; #E3FFb9; #C1F472; #7ac900;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
          </linearGradient>
        </defs>
        <Threshold
          id="versus-threshold"
          data={graphData.slice(0, graphIndex + 1)}
          x={(d, i) => xScale(getX(d, i))}
          y0={(d, i) => yScale(getY(d, i, "before"))}
          y1={(d, i) => yScale(getY(d, i, "loanOnly"))}
          clipAboveTo={0}
          clipBelowTo={yMax}
          curve={curveLinear}
          belowAreaProps={{
            fill: "#FF3636",
            fillOpacity: 0,
          }}
          aboveAreaProps={{
            fill: "url(#savings-gradient)",
            fillOpacity: 1,
          }}
        />
        {hasAfter ? (
          <Threshold
            id="after-threshold"
            data={graphData.slice(0, graphIndex + 1)}
            x={(d, i) => xScale(getX(d, i))}
            y1={(d, i) => yScale(getY(d, i, "after"))}
            y0={(d, i) => yScale(getY(d, i, "loanOnly"))}
            clipAboveTo={0}
            clipBelowTo={yMax}
            curve={curveLinear}
            belowAreaProps={{
              fill: "#FFA5A5",
              fillOpacity: 1,
            }}
            aboveAreaProps={{
              fill: "url(#savings-gradient)",
              fillOpacity: 1,
            }}
          />
        ) : (
          <></>
        )}
        {graphIndex > 0 ? (
          <>
            <SavingsPolygon
              graphIndex={graphIndex}
              previous={graphData[graphIndex - 1]}
              current={graphData[graphIndex]}
              savings={yearlyDifference[graphIndex]}
              xScale={xScale}
              yScale={yScale}
            />
            <Markers
              graphIndex={graphIndex}
              previous={graphData[graphIndex - 1]}
              current={graphData[graphIndex]}
              savings={yearlyDifference[graphIndex]}
              xScale={xScale}
              yScale={yScale}
            />
          </>
        ) : (
          <></>
        )}
        <Group>
          {hasAfter ? (
            <LinePath
              curve={curveLinear}
              data={graphData}
              x={(d, i) => xScale(getX(d, i))}
              y={(d, i) => yScale(getY(d, i, "after"))}
              fill="transparent"
              stroke="url(#afterGrad)"
              strokeWidth={5}
              strokeOpacity={1}
              shapeRendering="geometricPrecision"
            />
          ) : (
            <></>
          )}
          <LinePath
            curve={curveLinear}
            data={graphData}
            x={(d, i) => xScale(getX(d, i))}
            y={(d, i) => yScale(getY(d, i, "before"))}
            fill="transparent"
            stroke="url(#beforeGrad)"
            strokeWidth={5}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
          />
          <LinePath
            curve={curveLinear}
            data={graphData}
            x={(d, i) => xScale(getX(d, i))}
            y={(d, i) => yScale(getY(d, i, "loanOnly"))}
            fill="transparent"
            stroke="url(#loanGrad)"
            strokeWidth={5}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
          />
        </Group>
      </svg>
    </div>
  ) : (
    <></>
  );
};

export default LoanChart;
