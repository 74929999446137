import React, { useState } from "react";
import tw, { styled } from "twin.macro";

import roofBackground from "../assets/roof-dev.jpg";

import systemSizeIcon from "../assets/icons/systemSize.svg";
import panels from "../assets/icons/panels.svg";
import production from "../assets/icons/production.svg";
import panelIcon from "../assets/icons/panelIcon.svg";
import inverterIcon from "../assets/icons/inverterIcon.svg";
import inverterType2Icon from "../assets/icons/inverter2icon.svg";
import dottedLine from "../assets/icons/dottedLine.svg";
import formatter from "../utils/formatter";

const Wrapper = styled.div`
  ${tw`w-screen flex justify-center`}
  background-color: rgb(100, 100, 100);
`;
const Background = styled.div`
  min-height: 800px;
  ${tw`w-screen flex items-center justify-center`}
  background-position: center center;
  background-repeat: no-repeat;
  &.has-design-image{
    background-size: 100%;
  
    @media screen and (min-width: 1650px) {
    background-size: 95%;
    }
  @media screen and (min-width: 1800px) {
    background-size: 80%;
  }
  @media screen and (min-width: 2200px) {
    background-size: 75%;
  }
  @media screen and (min-width: 2500px) {
    background-size: 70%;
  }    
  }
  }
`;

const Container = styled.div`
  ${tw`flex items-center justify-between px-8 bg-center`}
  min-width: 1200px;
  img {
    ${tw`shadow-xl`}
    width: 307px;
  }
`;

const SubTitle = tw.h2`
    text-lg uppercase text-center font-light tracking-wider text-sunobi-dark py-8
`;

const Info = tw.div`
    bg-white bg-opacity-75 p-8 rounded-lg 
`;

const TD = tw.td`
    text-xs tracking-wider text-sunobi-normal uppercase font-light px-4
`;

const TDSmall = styled.td`
    ${tw`flex flex-col items-center justify-center`}
    height: 75px !important;
    img{
      width: 80px;
      margin-bottom: 7px;
    }
`;

const SnugTD = tw.td`
    leading-none
`;

const Value = tw.span`
    text-2xl uppercase text-left text-sunobi-dark font-light tracking-wider 
`;

const Label = tw.span`
    text-xs text-left font-light tracking-wider text-sunobi-dark pl-1 leading-none
    
`;
const SmallValue = styled.span`
  ${tw`text-xs text-left text-sunobi-dark font-thin tracking-wider`}
  font-size: 0.675rem;
`;

const Design = ({
  systemSize = 10,
  totalNumberOfPanels = 20,
  annualProduction = 24000,
  documents,
  panelAsset = {},
  inverterAsset = {},
  batteryAsset = {}
}) => {
  const [renderTimestamp, setRenderTimestamp] = useState(Date.now());
  const designImages = documents.filter(
    (d) => d.type == "Customer Design Image"
  );
  const preferenceFormImage = documents.find(
    (d) => d.type == "Design Preference Form 1"
  ); // Find not filter!
  const backgroundStyle = {
    backgroundColor: "rgba(100,100,100)",
  };
  // Backup
  if (designImages.length) {
    backgroundStyle.backgroundImage = `url(${designImages[0].url}/${renderTimestamp})`;
  }

  /*
  inverter
    brandURL: "https://kota.quickbase.com/up/bq5qwg768/a/r39/e23/v0"
    modelURL: "https://kota.quickbase.com/up/bq5qwg768/a/r39/e22/v0"
    name: "SolarEdge"
    type: "Inverter"
    watts: null

  panel
    brandURL: "https://kota.quickbase.com/up/bq5qwg768/a/r368/e23/v0"
    modelURL: "https://kota.quickbase.com/up/bq5qwg768/a/r368/e22/v0"
    name: "Q CELL 395"
    type: "Panel"
    watts: 395

    "battery": {
        "type": "Battery",
        "watts": null,
        "name": "Enphase IQ Battery 5P (Grid Tied)",
        "brandURL": "https://kota.quickbase.com/up/bq5qwg768/a/r3084073/e23/v0",
        "modelURL": "https://kota.quickbase.com/up/bq5qwg768/a/r3084073/e22/v0"
    },
  */

  return (
    <Wrapper className="min-w-1280">
      <Background
        className={
          `min-w-1280 ` + designImages.length ? "has-design-image" : ""
        }
        style={backgroundStyle}
      >
        <Container>
          <Info className="blur-background" style={{ width: "320px" }}>
            <SubTitle>Your solar design</SubTitle>
            <table className="table">
              <tbody>
                {panelAsset.brandURL ? (
                  <tr>
                    <td>
                      <img src={panelIcon} style={{ width: "30px" }} />
                    </td>
                    <TD>Panels</TD>
                    <TDSmall>
                      <img src={panelAsset.brandURL}/>
                      <SmallValue
                        style={{
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        {panelAsset.name}
                      </SmallValue>
                    </TDSmall>
                  </tr>
                ) : (
                  <></>
                )}
                {inverterAsset.brandURL ? (
                  <tr>
                    <td>
                      <img
                        src={
                          inverterAsset.type == "Inverter"
                            ? inverterIcon
                            : inverter2icon
                        }
                        style={{ width: "30px", marginBottom: '2px' }}
                      />
                    </td>
                    <TD>Inverters</TD>
                    <TDSmall>
                      <img src={inverterAsset.brandURL}/>
                      <SmallValue
                        style={{
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        {inverterAsset.name}
                      </SmallValue>
                    </TDSmall>
                  </tr>
                ) : (
                  <></>
                )}
                {batteryAsset.brandURL ? (
                  <tr>
                    <td>
                      <img
                        src={
                          batteryAsset.type == "Battery"
                            ? inverterIcon
                            : inverter2icon
                        }
                        style={{ width: "30px" }}
                      />
                    </td>
                    <TD>Battery</TD>
                    <TDSmall>
                      <img src={batteryAsset.brandURL}/>
                      <SmallValue
                        style={{
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        {batteryAsset.name}
                      </SmallValue>
                    </TDSmall>
                  </tr>
                ) : (
                  <></>
                )}

                {panelAsset.brandURL || inverterAsset.brandURL  || batteryAsset.brandURL ? (
                  <tr>
                    <td
                      colspan="3"
                      style={{
                        height: "50px",
                      }}
                    >
                      <img src={dottedLine} style={{ width: "100%" }} />
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                <tr>
                  <td>
                    <img src={systemSizeIcon} style={{ width: "30px" }} />
                  </td>
                  <TD>
                    Design
                    <br />
                    System Size
                  </TD>
                  <td>
                    <Value>{formatter(systemSize, 3)}</Value>
                    <Label>kW</Label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={panels}
                      style={{ width: "20px", marginLeft: "5px" }}
                    />
                  </td>
                  <TD>
                    N<span style={{ textTransform: "none" }}>o</span> Of panels
                  </TD>
                  <td>
                    <Value>{totalNumberOfPanels}</Value>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={production} style={{ width: "30px" }} />
                  </td>
                  <TD>Production</TD>
                  <SnugTD>
                    <Value>{formatter(annualProduction / 12, 0)}</Value>
                    <Label>kW</Label>
                    <br />
                    <SmallValue>
                      {formatter(annualProduction, 0)} kWh
                    </SmallValue>
                  </SnugTD>
                </tr>
              </tbody>
            </table>
          </Info>
          {!designImages.length ? <img src={preferenceFormImage.url} /> : <></>}
        </Container>
      </Background>
    </Wrapper>
  );
};

export default Design;
