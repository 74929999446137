import React from "react";
import tw, { styled } from "twin.macro";
import { format, fromUnixTime } from "date-fns";
import roofBackground from "../assets/roof-dev.jpg";

import systemSizeIcon from "../assets/icons/systemSize.svg";

import ProductionGraph from "../components/productionGraph";

import getConsumption from "../utils/getConsumption";
import getSolar from "../utils/getSolar";

import iconConsumption from "../assets/icons/productionYearsConsumption.svg";
import iconProduction from "../assets/icons/productionYearsProduction.svg";
import iconOffset from "../assets/icons/productionOffset.svg";

import iconWinter from "../assets/icons/winter.png";
import iconSpring from "../assets/icons/spring.png";
import iconSummer from "../assets/icons/summer.png";
import iconAutumn from "../assets/icons/autumn.png";

const Background = styled.div`
  min-height: 900px;
  ${tw`w-screen flex items-center justify-center bg-cover`}
`;

const Container = tw.div`
  flex items-center flex-col justify-start px-8 bg-center
`;

const Title = tw.h1`
    text-2xl uppercase text-center text-white font-light tracking-wider py-4
`;
const SubTitle = tw.h2`
    text-lg text-center font-thin tracking-wider text-white opacity-75 mb-16
`;
const Row = tw.div`
    flex justify-between items-center my-10 w-11/12
`;

const ValueRow = tw(Row)`w-11/12 px-8`;

class Production extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yearCreated: false,
      consumptionPeriods: [],
      solarPeriods: [],
      proposalRecordID: false,
    };
  }

  componentDidMount() {
    const { proposalRecordID, dateCreated, utilityMode, utilityData } =
      this.props;

    this.setState(
      {
        proposalRecordID,
        yearCreated: parseInt(format(fromUnixTime(dateCreated / 1000), "yyyy")),
      },
      () => {
        this.getGraphData();
      }
    );
  }


  async getSolarProd(offset = 0) {
    // Get 12 month analysis
    const { numberOfSolarArrays = 0 } = this.props
    if (!numberOfSolarArrays)
      return []
    const solarResult = await getSolar({
      providerAccountId: "sunobi-kota-" + this.state.proposalRecordID,
      numberOfSolarArrays,
      fromDateTime: `${this.state.yearCreated - offset}-12-01`,
    });

    const { proposalRecordID, yearCreated } = this.state

    const specificResult = solarResult.results[0]
    if (!specificResult)
      return
    const solarSeries = specificResult.series.find(
      (s) => s.scenario == "solar" && s.seriesPeriod == "MONTH"
    );
    const solarSeriesData = specificResult.seriesData.filter(
      (s) => s.seriesId == solarSeries.seriesId
    );
    return solarSeriesData;
  }

  async getGraphData() {
    if (this.state.proposalRecordID) {
      const { utilityData = "[]", utilityMode } = this.props;
      let periods = [];
      const parsedPeriods =
        utilityData == "" || (periods = JSON.parse(utilityData));

      if (
        !["auto", ""].includes(utilityMode) &&
        parsedPeriods &&
        parsedPeriods.length
      ) {
        periods = JSON.parse(utilityData);
      } else {
        periods = await getConsumption({
          providerAccountId: "sunobi-kota-" + this.state.proposalRecordID,
          fromDateTime: `${this.state.yearCreated - 1}-12-01`,
          toDateTime: `${this.state.yearCreated + 1}-01-01`,
        });
      }
      if (periods && periods.length) {
        this.setState({ consumptionPeriods: periods });
        const solarPeriods = await this.getSolarProd(1);
        this.setState({ solarPeriods }, async () => {
          const futureSolarPeriods = await this.getSolarProd(0);
          this.setState({
            solarPeriods: [...this.state.solarPeriods, futureSolarPeriods[0]],
          });
        });
        if (this.props.consumptionOriginal != this.props.consumption) {
          const coefficient =
            this.props.consumption / this.props.consumptionOriginal;
          const consumptionPeriods = [...this.state.consumptionPeriods];
          consumptionPeriods.forEach((c) => {
            c.itemQuantity *= coefficient;
          });

          this.setState({ consumptionPeriods });
        } else {
          this.setState({ consumptionPeriods: periods });
        }
      }
    }
  }

  render() {
    const { consumptionPeriods, solarPeriods } = this.state;
    const {
      production,
      consumption,
      offset,
      utilityMode,
      utilityData = "[]",
    } = this.props;

    const parsedPeriods = utilityData == "" || JSON.parse(utilityData);
    const isNotAuto =
      !["auto", ""].includes(utilityMode) &&
      parsedPeriods &&
      parsedPeriods.length;

    var formatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
    });

    const renderSeasons = () => {
      const seasons = [
        { i: iconWinter, d: "winter", w: "50%" },
        { i: iconSpring, d: "spring", w: "33%" },
        { i: iconSummer, d: "summer", w: "50%" },
        { i: iconAutumn, d: "autumn", w: "50%" },
      ];
      const Season = tw.div`w-1/12 flex flex-col justify-center items-center`;
      const Desc = tw.p`text-xs uppercase text-center text-white font-thin tracking-wider pt-2`;

      return seasons.map((s, i) => (
        <Season key={`season-${i}`}>
          <img src={s.i} style={{ width: s.w ? s.w : "100%" }} />
          <Desc>{s.d}</Desc>
        </Season>
      ));
    };

    const renderDataPoint = ({
      icon,
      left = "",
      center = "",
      value,
      right,
    }) => {
      const Label = tw.p`
                text-sm font-thin tracking-widest text-white uppercase
            `;

      const Value = tw.span`
                text-2xl px-1 font-normal tracking-widest text-white
            `;
      return (
        <div>
          <img src={icon} style={{ width: "50px" }} />
          <Label>
            {left ? left + " " : <></>}
            {center ? (
              <span style={{ fontWeight: "400" }}>{center}</span>
            ) : (
              <></>
            )}
            <Value>{formatter.format(value)}</Value>
            <span style={{ fontSize: "1.1em", textTransform: "none" }}>
              {right}
            </span>
          </Label>
        </div>
      );
    };

    return (
      <Background className="min-w-1280" style={{ backgroundColor: "#383838" }}>
        <Container>
          <Title>Production / consumption</Title>
          <SubTitle>
            When you produce more electricity than you use it will be credited
            <br />
            into your virtual "Energy Bank" with your utility company.
          </SubTitle>

          {(!["auto", ""].includes(utilityMode) &&
            consumptionPeriods.length == 12 &&
            solarPeriods.length == 13) ||
            (["auto", ""].includes(utilityMode) &&
              consumptionPeriods.length == 13 &&
              solarPeriods.length == 13) ? (
            <ProductionGraph
              consumption={consumptionPeriods}
              solar={solarPeriods}
              isNotAuto={isNotAuto}
            />
          ) : (
            <div
              style={{
                height: "226px",
                width: "1040px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SubTitle>Loading...</SubTitle>
            </div>
          )}
          <Row style={{ paddingLeft: "6.5rem", paddingRight: "6.5rem" }}>
            {renderSeasons()}
          </Row>
          <ValueRow>
            {renderDataPoint({
              icon: iconConsumption,
              left: "Years",
              center: "Consumption",
              value: consumption,
              right: "kWh",
            })}
            {renderDataPoint({
              icon: iconProduction,
              left: "Years",
              center: "Production",
              value: production,
              right: "kWh",
            })}
            {renderDataPoint({
              icon: iconOffset,
              center: "Offset",
              value: offset * 100,
              right: "%",
            })}
          </ValueRow>
        </Container>
      </Background>
    );
  }
}

export default Production;
