import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import CustomSwitch from "../components/customSwitch";
import solarSteps from "../assets/step-1.png";
import solarStepsYours from "../assets/step-2.png";
const Background = styled.div`
  ${tw`w-screen flex items-center justify-center bg-cover`}
  min-height: 850px;
`;
const Container = styled.div`
  ${tw`flex items-center flex-col justify-start px-8 bg-center`}
  max-width: 1280px;
  margin-top: 50px;
`;

const Title = tw.h1`
    text-2xl uppercase text-center text-sunobi-dark font-light tracking-wider pb-4
`;
const SubTitle = styled.h2`
  ${tw`text-lg text-center font-thin tracking-wider text-sunobi-normal opacity-75`}
  transition: all 0.3s linear;
  &.hidden {
    transition: all 0.3s linear;
    opacity: 0;
  }
`;

const Img = tw.img`
    mt-24
`;

const StepWrapper = styled.div`
  height: 620px;
  position: relative;
  width: 1216px;
  img {
    transition: all 0.3s linear;
    position: absolute;
    width: 1216px;
    top: 0;
    opacity: 1;
    &.hidden {
      transition: all 0.3s linear;
      opacity: 0;
    }
  }
`;
const SwitchKey = styled.a`
  ${tw`text-center font-thin tracking-wider text-sunobi-normal opacity-75 uppercase`}
  margin-top: 8px;
  font-size: 16px;
  cursor: pointer;
`;

const Steps = () => {
  const [isYours, setIsYours] = useState(false);
  return (
    <Background className="min-w-1280" style={{ backgroundColor: `#F4F4F4` }}>
      <Container>
        <Title>SOLAR STEPS</Title>
        <SubTitle>
          {isYours
            ? "After our consultation, we take care of everything. All that’s required of you is to be home for 2 of the steps."
            : "Here are the steps to go Solar. Don’t worry, we take care of it all."}
        </SubTitle>
        <StepWrapper>
          <Img src={solarSteps} className={isYours ? "hidden" : ""} />
          <Img src={solarStepsYours} className={isYours ? "" : "hidden"} />

          <SubTitle
            style={{ marginTop: "500px" }}
            className={isYours ? "" : "hidden"}
          >
            After installation, we’ll start working with your utility to get
            your system connected to the grid.
          </SubTitle>
        </StepWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "330px",
            marginBottom: "100px",
          }}
        >
          <SwitchKey onClick={() => setIsYours(false)}>All Steps</SwitchKey>
          <div>
            <CustomSwitch
              mode={isYours}
              updateMode={(val) => setIsYours(val)}
            />
          </div>
          <SwitchKey onClick={() => setIsYours(true)}>Your Steps</SwitchKey>
        </div>
      </Container>
    </Background>
  );
};

export default Steps;
