import React from "react";
import tw, { styled } from "twin.macro";
import * as S from "../styles/versus-incentives-styles";
import Switch from "./incentiveSwitch";

/* Images */
import bankIcon from "../assets/incentives/bank.png";
import federalIcon from "../assets/incentives/federal.png";
import multipleIcon from "../assets/incentives/multiple.png";
import pigIcon from "../assets/incentives/pig.png";
import stateIcon from "../assets/incentives/state.png";
import utilityIcon from "../assets/incentives/utility.png";
import infoIcon from "../assets/incentives/info.png";

export default class VersusIncentives extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }
  render() {
    const moneyFormat = (value = 0) =>
      value
        ? value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
        : 0;
    const {
      summaryTaxCredit,
      stateRebate = 0,
      utilityRebate = 0,

      applyFederalRebate,
      applyStateRebate,
      applyUtilityRebate,
    } = this.props.proposal;

    /* Incentive related logic */

    const allIncentives = summaryTaxCredit + stateRebate + utilityRebate;

    const morethanFederal = stateRebate + utilityRebate > 0;

    const threeWayValue =
      applyFederalRebate &&
        (stateRebate > 0 ? applyStateRebate == 1 : true) &&
        (utilityRebate > 0 ? applyUtilityRebate == 1 : true)
        ? 1
        : !applyFederalRebate &&
          !(stateRebate > 0 ? applyStateRebate : false) &&
          !(utilityRebate > 0 ? applyUtilityRebate : false)
          ? 0
          : 0.5;

    const toggleThreeWayFn = () => {
      // The federal is the only rebate availabe
      if (!morethanFederal) {
        this.props.updateProposalFn([
          {
            fid: 1232,
            value: !applyFederalRebate,
          },
        ]);
      } else {
        this.setState({ expanded: !this.state.expanded });
      }
    };

    const toggleAll = (value) => {
      this.props.updateProposalFn([
        {
          fid: 1232,
          value,
        },
        {
          fid: 1215,
          value,
        },
        {
          fid: 1213,
          value,
        },
      ]);
    };

    const { expanded } = this.state;
    return (
      <S.Wrapper style={{ marginTop: "30px" }}>
        <S.Row>
          {morethanFederal ? (
            <S.Row>
              <img
                src={multipleIcon}
                style={{ width: "39px", height: "32px" }}
              />
              <S.Label style={{ margin: "0 15px" }}>Incentives Total</S.Label>
            </S.Row>
          ) : (
            <S.Row>
              <img
                src={federalIcon}
                style={{ width: "78px", height: "52px" }}
              />
              <S.Label style={{ margin: "0 15px" }}>Federal Credit</S.Label>
            </S.Row>
          )}

          <div>
            <S.Value>${moneyFormat(allIncentives)}</S.Value>
          </div>

          {!expanded ? (
            <S.Rebate
              onClick={toggleThreeWayFn}
              style={{ width: "152px", marginRight: 0 }}
            >
              <S.Pig
                src={pigIcon}
                style={{
                  marginRight: "10px",
                  opacity: threeWayValue == 1 ? 0.3 : 1,
                }}
              />
              <div>
                <Switch
                  mode={
                    threeWayValue == 1
                      ? "right"
                      : threeWayValue == 0.5
                        ? "middle"
                        : "left"
                  }
                />
              </div>
              <S.Bank
                src={bankIcon}
                style={{
                  opacity: threeWayValue == 0 ? 0.3 : 1,
                }}
              />
            </S.Rebate>
          ) : (
            <></>
          )}
          {morethanFederal ? (
            <a
              onClick={toggleThreeWayFn}
              style={{ marginLeft: "20px", cursor: "pointer" }}
            >
              <img
                src={infoIcon}
                style={{ width: "22.5px", height: "22.5px" }}
              />
            </a>
          ) : (
            <></>
          )}
        </S.Row>
        {/* Start Individual Row */}
        <S.Expander className={expanded ? "visible" : ""}>
          <S.Row style={{ marginTop: "45px", alignItems: "flex-start" }}>
            <div
              style={{
                alignItems: "center",
                marginRight: "15px",
                marginTop: "-14px",
              }}
            >
              <S.SubLabel style={{ textAlign: "center", marginBottom: "2px" }}>
                Keep
              </S.SubLabel>
              <S.Rebate
                onClick={() => toggleAll(false)}
                className={threeWayValue == 0 ? "active" : "trans"}
              >
                <S.Pig src={pigIcon} />
                <S.Label style={{ marginLeft: "5px" }}>All</S.Label>
              </S.Rebate>
            </div>
            <div style={{ position: "relative" }}>
              <S.RebateHeader>
                <img
                  src={federalIcon}
                  style={{ width: "78px", height: "52px" }}
                />
                <S.SubLabel style={{ marginBottom: "17px", marginLeft: "5px" }}>
                  Federal Credit
                </S.SubLabel>
              </S.RebateHeader>
              <S.Rebate
                onClick={() =>
                  this.props.updateProposalFn([
                    {
                      fid: 1232,
                      value: !applyFederalRebate,
                    },
                  ])
                }
              >
                <S.SubValue>$ {moneyFormat(summaryTaxCredit)}</S.SubValue>
                <S.Row>
                  <S.Pig
                    src={pigIcon}
                    style={{ opacity: applyFederalRebate ? 0.3 : 1 }}
                  />
                  <Switch mode={applyFederalRebate ? "right" : "left"} />
                  <S.Bank
                    src={bankIcon}
                    style={{ opacity: applyFederalRebate ? 1 : 0.3 }}
                  />
                </S.Row>
              </S.Rebate>
            </div>

            {stateRebate != 0 ? (
              <div style={{ marginHorizontal: "15px", position: "relative" }}>
                <S.RebateHeader className="state">
                  <img
                    src={stateIcon}
                    style={{ width: "53px", height: "47px" }}
                  />
                  <S.SubLabel
                    style={{ marginBottom: "15px", marginLeft: "5px" }}
                  >
                    State Credit
                  </S.SubLabel>
                </S.RebateHeader>
                <S.Rebate
                  style={{ height: "36px" }}
                  onClick={() =>
                    this.props.updateProposalFn([
                      {
                        fid: 1215,
                        value: !applyStateRebate,
                      },
                    ])
                  }
                >
                  <S.SubValue>$ {moneyFormat(stateRebate)}</S.SubValue>
                  <S.Row>
                    <S.Pig
                      src={pigIcon}
                      style={{ opacity: applyStateRebate ? 0.3 : 1 }}
                    />
                    <Switch mode={applyStateRebate ? "right" : "left"} />
                    <S.Bank
                      src={bankIcon}
                      style={{ opacity: applyStateRebate ? 1 : 0.3 }}
                    />
                  </S.Row>
                </S.Rebate>
              </div>
            ) : (
              <></>
            )}
            {utilityRebate != 0 ? (
              <div style={{ marginHorizontal: "15px", position: "relative" }}>
                <S.RebateHeader className="utility">
                  <img
                    src={utilityIcon}
                    style={{ width: "54px", height: "55px" }}
                  />
                  <S.SubLabel style={{ marginBottom: "17px" }}>
                    Utility Credit
                  </S.SubLabel>
                </S.RebateHeader>
                <S.Rebate
                  style={{ height: "36px" }}
                  onClick={() =>
                    this.props.updateProposalFn([
                      {
                        fid: 1213,
                        value: !applyUtilityRebate,
                      },
                    ])
                  }
                >
                  <S.SubValue>$ {moneyFormat(utilityRebate)}</S.SubValue>
                  <S.Row>
                    <S.Pig
                      src={pigIcon}
                      style={{ opacity: applyUtilityRebate ? 0.3 : 1 }}
                    />
                    <Switch mode={applyUtilityRebate ? "right" : "left"} />
                    <S.Bank
                      src={bankIcon}
                      style={{ opacity: applyUtilityRebate ? 1 : 0.3 }}
                    />
                  </S.Row>
                </S.Rebate>
              </div>
            ) : (
              <></>
            )}
            <div
              style={{
                alignItems: "center",
                marginLeft: "15px",
                marginTop: "-14px",
              }}
            >
              <S.SubLabel style={{ textAlign: "center", marginBottom: "2px" }}>
                Invest
              </S.SubLabel>
              <S.Rebate
                onClick={() => toggleAll(true)}
                className={threeWayValue == 1 ? "active" : "trans"}
              >
                <S.Bank src={bankIcon} />
                <S.Label style={{ marginLeft: "5px" }}>All</S.Label>
              </S.Rebate>
            </div>
          </S.Row>
        </S.Expander>
      </S.Wrapper>
    );
  }
}
