import React from "react";
import tw, { styled } from "twin.macro";

const Wrapper = styled.div`
  ${tw`w-screen h-screen flex justify-center items-center flex-col`}
  background-color: rgba(34,34,34);
  iframe {
    width: 300px;
    height: 300px;
  }
  p {
    ${tw`text-xl font-light text-white text-center tracking-wide mt-3`}
  }
`;

export default () => (
  <Wrapper>
    <iframe src="/hype-embed.html" />
    <p>Please you use the link provided by your sales rep.</p>
  </Wrapper>
);
