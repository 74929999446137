import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";

const Container = styled.div`
  position: relative;
  width: 80px;
  padding-left: 5px;
  padding-right: 5px;
`;
const Bar = styled.div`
  position: absolute;
  top: 5px;
  width: 70px;
  height: 30px;
  background-color: #cecece;
  border-radius: 20px;
  transition: all 0.1s linear 0.2s;
  cursor: pointer;
  &.on {
    background-color: #97d33a;
  }
`;
const Ball = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.25);
  transform: translate(-10px);
  transition: all 0.3s linear;
  &.on {
    transform: translate(40px);
    transition: all 0.3s linear;
  }
`;

export default ({ mode, updateMode, enableDisable = false }) => {
  useEffect(() => {
    setOn(mode);
  }, [mode]);
  const [on, setOn] = useState(false);

  return (
    <>
      <Container>
        <Bar
          className={on && !enableDisable ? "on" : ""}
          onClick={() => {
            updateMode(on ? false : true);
            setOn(on ? false : true);
          }}
        ></Bar>
        <Ball
          className={on ? "on" : ""}
          onClick={() => {
            updateMode(on ? true : false);
            setOn(on ? true : false);
          }}
        ></Ball>
      </Container>
    </>
  );
};
