import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";

import kotaBackground from "../assets/kota-background.jpg";
import versus from "../assets/tradVsSolar-background.jpg";
import badgeImage from "../assets/taxCreditBadge.png";
import savingsBackground from "../assets/versus-savings-background.png";

import enviroBackground from "../assets/enviro-background.jpg";
import plant from "../assets/enviro-plant.png";
import car from "../assets/enviro-car.png";
import trash from "../assets/enviro-trash.png";
import tree from "../assets/enviro-tree.png";
import footerBackground from "../assets/footer-background.jpg";

import elementBackground from "../assets/element-background.jpg";
import empireBackground from "../assets/empire-background.jpg";
import titanBackground from "../assets/titan-background.jpg";

const Wrapper = styled.div`
  ${tw`w-screen h-screen flex justify-center items-center flex-col`}
  background-color: rgba(34,34,34);
  iframe {
    width: 300px;
    height: 300px;
  }
  p {
    ${tw`text-xl font-light text-white text-center tracking-wide mt-3`}
  }
  #hidden-images {
    display: none;
  }
`;

export default ({ proposal = null, designImages = null }) => {
  const [beginLoading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000); // Begin loading Assets 1 second after the initial loading screen shows
  }, []);

  return (
    <Wrapper>
      <iframe src="/hype-embed.html" />
      {proposal ? (
        <p>
          Thanks for your patience {proposal.leadName}, we are almost ready!
        </p>
      ) : (
        <p>Our Ninjas are building your proposal</p>
      )}
      {beginLoading ? (
        <div id="hidden-images">
          <img src={kotaBackground} />
          {proposal.installerRecordID == 5 ? (
            <img src={elementBackground} />
          ) : (
            <></>
          )}
          {proposal.installerRecordID == 3 ? (
            <img src={empireBackground} />
          ) : (
            <></>
          )}
          {proposal.installerRecordID == 2 ? (
            <img src={titanBackground} />
          ) : (
            <></>
          )}
          <img src={versus} />
          <img src={badgeImage} />
          <img src={savingsBackground} />
          <img src={enviroBackground} />
          <img src={plant} />
          <img src={car} />
          <img src={trash} />
          <img src={tree} />
          <img src={footerBackground} />
          {designImages.length ? (
            <img src={designImages[designImages.length - 1].url} />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
