import React from "react";
import tw, { styled } from "twin.macro";
import formatter from "../utils/formatter";

import versus from "../assets/tradVersusBackground.jpg";

import traditionalIcon from "../assets/icons/versus/traditional.svg";
import solarIcon from "../assets/icons/versus/solar.svg";
import SavingsBadge from "../components/savingsBadge";
import VersusGraph from "../components/versusGraph";

const floatFormatter = (value = 0) =>
  value.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

class Versus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphIndex: 0,
    };
  }

  render() {
    // TO DO we need to fix this payment initial
    const { proposal, levelizedRate } = this.props
    const {
      estimatedProductionOverride,
      totalAnnualEnergyConsumption,
      totalAnnualEnergyConsumptionModified,
      totalAnnualEnergyCost,
      utilityEscalator,
      stateRebate = 0,
      utilityRebate = 0,
      isLease = false,
      leaseEscalator = 0
    } = proposal;




    return (
      <div>
        <Background
          className="min-w-1280"
          style={{
            backgroundImage: `url(${versus})`,
            backgroundColor: "#F4F4F4",
            minHeight: stateRebate || utilityRebate ? "950px" : "905px",
          }}
        >
          <Container>
            <Column>
              <TitleDiv
                style={{
                  right: "50px",
                  top: "30px",
                }}
              >
                <Title style={{ textAlign: "right" }}>Traditional</Title>
                <img
                  src={traditionalIcon}
                  style={{ width: "20px", marginLeft: "10px" }}
                />
              </TitleDiv>

              <Inputs style={{ position: "absolute", left: 0 }}>
                <Input>
                  <Label>Your average Rate</Label>
                  <Value>
                    ${" "}
                    {formatter(
                      totalAnnualEnergyCost / totalAnnualEnergyConsumption,
                      2
                    )}
                  </Value>
                </Input>
                <Input>
                  <Label>Annual Increase</Label>
                  <Value>{formatter(utilityEscalator, 2)} %</Value>
                </Input>
                <Input>
                  <Label>Consumption PA</Label>
                  <Value>
                    {formatter(totalAnnualEnergyConsumptionModified, 0)}
                    <span> kWhs</span>
                  </Value>
                </Input>
              </Inputs>
            </Column>
            <Column>
              <TitleDiv style={{ left: "50px", top: "30px" }}>
                <img
                  src={solarIcon}
                  style={{ width: "30px", marginRight: "10px" }}
                />
                <Title style={{ textAlign: "left" }}>Solar</Title>
              </TitleDiv>
              <Inputs style={{ position: "absolute", right: 0 }}>
                <Input>
                  <Label>Your average Rate</Label>
                  <Value>
                    ${" "}
                    {formatter(levelizedRate, 2)}
                  </Value>
                </Input>
                <Input>
                  <Label>Annual Increase</Label>
                  <Value>{((isLease ? leaseEscalator : 0) * 100).toFixed(2) + "%"}</Value>
                </Input>
                <Input>
                  <Label>Production PA</Label>
                  <Value>
                    {formatter(estimatedProductionOverride, 0)}
                    <span> kWhs</span>
                  </Value>
                </Input>
              </Inputs>
            </Column>

            {this.props.proposal && this.props.graphData && (
              <>
                <SavingsBadge
                  graphData={this.props.graphData}
                  fullSavingsTerm={this.props.fullSavingsTerm}
                />
                <VersusGraph
                  data={this.props.graphData}
                  shouldRender={this.props.shouldRender}
                  proposal={this.props.proposal}
                  keepITC={this.props.keepITC}
                  paymentAdjusted={this.props.paymentAdjusted}
                  graphIndex={this.state.graphIndex}
                  updateGraphIndexFn={(val) =>
                    this.setState({ graphIndex: val })
                  }
                  fullSavingsTerm={this.props.fullSavingsTerm}
                  updateProposalFn={this.props.updateProposalFn}
                />
              </>
            )}
          </Container>
        </Background>
      </div>
    );
  }
}

export default Versus;

const Background = styled.div`
  ${tw`w-screen flex items-start justify-center`}
  min-height: 900px;
  background-size: cover;
  background-size: auto 600px;

  background-position: 50% 0%;
  background-repeat: no-repeat;
`;
const Container = styled.div`
  ${tw`flex items-center justify-start px-8 bg-center relative`}
  width: 1220px;
`;
const Column = styled.div`
  padding-top: 370px;
  ${tw`relative w-1/2`}
`;

const TitleDiv = tw.div`
    absolute
    flex
    items-center
`;
const Title = tw.h1`
    text-2xl uppercase text-white font-light tracking-widest
`;

const Inputs = styled.div`
  ${tw`flex justify-start w-8/12`}
  z-index: 1000;
`;

const Input = tw.div`
    w-1/3
    px-3
    
`;

const Label = styled.p`
  font-size: 9px;
  ${tw`
  uppercase font-light text-sunobi-normal text-left tracking-wide
  pb-1`};
`;

const Value = styled.div`
  ${tw`text-base font-light text-sunobi-normal text-left bg-white bg-opacity-50 px-3 py-1 rounded-md`}
  span {
    font-size: 9px;
  }
`;
