import React, { PureComponent } from "react";
import tw from "twin.macro";
import { AreaChart, Area, XAxis, ReferenceLine, ReferenceDot } from "recharts";
import GraphMask from "../assets/graphMask.png";
class ProductionGraph extends React.Component {
  constructor(props) {
    super(props);
    const months = [];

    if (this.props.isNotAuto) {
      months.push(
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec"
      );
    } else {
      months.push(
        "dec",
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov"
      );
    }
    this.state = {
      months,
      data: [],
    };
  }

  componentDidMount() {
    const { consumption, solar, isNotAuto } = this.props;
    const data = [];
    consumption.forEach((i, k) => {
      data.push({
        month: this.state.months[k],
        consumption: i.itemQuantity,
        production: solar[k + (isNotAuto ? 1 : 0)].qty,
      });
    });

    this.setState({
      data,
      smallDifferenceThreshold: 100,
    });
  }

  render() {
    const { isNotAuto } = this.props;
    const SunobiAxis = tw.div`
            flex justify-between w-full px-1
        `;

    const DateDiv = tw.div`
            w-1/12 flex justify-center
        `;
    const Date = tw.p`
            text-xs uppercase text-center text-white font-light tracking-widest pt-3
        `;

    const productionLegend = (props) => {
      const height = 16.8996;
      let productionOffset = 0;
      let productionLineAngle = 90;

      const firstProduction = this.state.data[0].production;
      const firstConsumption = this.state.data[0].consumption;
      if (firstProduction > firstConsumption) {
        if (
          firstProduction - firstConsumption <
            this.state.smallDifferenceThreshold &&
          firstProduction - firstConsumption > 0
        ) {
          productionOffset = -4;
          productionLineAngle = 110;
        }
      } else if (
        firstConsumption - firstProduction <
        this.state.smallDifferenceThreshold
      ) {
        productionOffset = 11.5;
        productionLineAngle = 65;
      }

      return (
        <svg
          x={props.cx - 42}
          y={props.cy - height / 1.5 + productionOffset}
          xmlns="http://www.w3.org/2000/svg"
          width="42.2538"
          height={height}
          viewBox="0 0 70.423 28.166"
        >
          <g id="Group_7209">
            <path
              id="Path_1391"
              data-name="Path 1391"
              d="M1305.412,874.213a8.465,8.465,0,1,0-8.465,8.465h0A8.464,8.464,0,0,0,1305.412,874.213ZM1297,881.154a6.94,6.94,0,0,1-6.94-6.94V874.2a6.94,6.94,0,1,1,6.94,6.956Z"
              transform="translate(-1281.449 -862.056)"
              fill="#fad401"
            />
            <path
              id="Path_1392"
              data-name="Path 1392"
              d="M1287.41,879.661l-1.083.59-.015.009a.5.5,0,0,0-.182.68l.245.444a.526.526,0,0,0,.444.261.47.47,0,0,0,.245-.069l1.083-.636.036-.02a.487.487,0,0,0,.161-.669l-.247-.442A.529.529,0,0,0,1287.41,879.661Z"
              transform="translate(-1281.262 -863.121)"
              fill="#fad401"
            />
            <path
              id="Path_1393"
              data-name="Path 1393"
              d="M1302.8,865.264l.443.245h0a.47.47,0,0,0,.246.069.524.524,0,0,0,.444-.266l.591-1.083.009-.014a.5.5,0,0,0-.2-.675l-.394-.2-.014-.008a.5.5,0,0,0-.675.2l-.591,1.083a.537.537,0,0,0-.03.046A.439.439,0,0,0,1302.8,865.264Z"
              transform="translate(-1282.532 -861.867)"
              fill="#fad401"
            />
            <path
              id="Rectangle_398"
              data-name="Rectangle 398"
              d="M.534,0H1.683a.533.533,0,0,1,.533.533V.995a.534.534,0,0,1-.534.534H.534A.534.534,0,0,1,0,.995V.534A.534.534,0,0,1,.534,0Z"
              transform="translate(25.538 11.367)"
              fill="#fad401"
            />
            <path
              id="Path_1394"
              data-name="Path 1394"
              d="M1291.2,865.415a.553.553,0,0,0,.485.245.7.7,0,0,0,.254-.048l.443-.3.016-.009a.5.5,0,0,0,.181-.68l-.64-1.033-.009-.017a.5.5,0,0,0-.679-.18l-.444.246-.015.009a.5.5,0,0,0-.181.68Z"
              transform="translate(-1281.607 -861.871)"
              fill="#fad401"
            />
            <path
              id="Path_1395"
              data-name="Path 1395"
              d="M1307.116,869.6a.526.526,0,0,0,.445.266.485.485,0,0,0,.245-.069l1.082-.591a.544.544,0,0,0,.2-.689l-.245-.443-.007-.014a.5.5,0,0,0-.682-.183l-1.082.59-.017.009a.5.5,0,0,0-.18.68Z"
              transform="translate(-1282.857 -862.215)"
              fill="#fad401"
            />
            <path
              id="Path_1396"
              data-name="Path 1396"
              d="M1307.859,879.657l-.017-.01a.5.5,0,0,0-.672.206l-.246.444-.008.016a.5.5,0,0,0,.2.674l1.083.59a.346.346,0,0,0,.212.069.518.518,0,0,0,.428-.266l.246-.443.009-.016a.5.5,0,0,0-.206-.673Z"
              transform="translate(-1282.861 -863.121)"
              fill="#fad401"
            />
            <rect
              id="Rectangle_399"
              data-name="Rectangle 399"
              width="2.215"
              height="1.529"
              rx="0.534"
              transform="translate(14.757 2.215) rotate(-89.923)"
              fill="#fad401"
            />
            <path
              id="Path_1397"
              data-name="Path 1397"
              d="M1285.082,875.591h1.283a.466.466,0,0,0,.44-.492v-.544a.493.493,0,0,0-.492-.493h-1.23a.494.494,0,0,0-.493.493v.544A.493.493,0,0,0,1285.082,875.591Z"
              transform="translate(-1281.149 -862.696)"
              fill="#fad401"
            />
            <path
              id="Path_1398"
              data-name="Path 1398"
              d="M1286.38,869.2l1.083.591h0a.494.494,0,0,0,.255.07.555.555,0,0,0,.435-.218l.246-.444.008-.015a.5.5,0,0,0-.206-.674l-1.033-.64-.016-.009a.5.5,0,0,0-.674.206l-.3.443-.009.017A.5.5,0,0,0,1286.38,869.2Z"
              transform="translate(-1281.267 -862.215)"
              fill="#fad401"
            />
            <path
              id="Path_1399"
              data-name="Path 1399"
              d="M1293.944,884.928h-2.555l-.127.88h2.616Z"
              transform="translate(-1281.662 -863.531)"
              fill="#fad401"
            />
            <path
              id="Path_1400"
              data-name="Path 1400"
              d="M1290.763,889.231l2.845,0,.061-.88h-2.779Z"
              transform="translate(-1281.624 -863.794)"
              fill="#fad401"
            />
            <path
              id="Path_1401"
              data-name="Path 1401"
              d="M1293.81,886.636h-2.67l-.127.88h2.731Z"
              transform="translate(-1281.643 -863.663)"
              fill="#fad401"
            />
            <path
              id="Path_1402"
              data-name="Path 1402"
              d="M1288.867,884.928h-2.556l-.243.88h2.616Z"
              transform="translate(-1281.263 -863.531)"
              fill="#fad401"
            />
            <path
              id="Path_1403"
              data-name="Path 1403"
              d="M1296.467,884.928l-.012.88h2.615l-.049-.88Z"
              transform="translate(-1282.062 -863.531)"
              fill="#fad401"
            />
            <path
              id="Path_1404"
              data-name="Path 1404"
              d="M1296.429,887.516h2.731l-.049-.88h-2.67Z"
              transform="translate(-1282.06 -863.663)"
              fill="#fad401"
            />
            <path
              id="Path_1405"
              data-name="Path 1405"
              d="M1285.117,889.231l2.845,0,.182-.88h-2.779Z"
              transform="translate(-1281.19 -863.794)"
              fill="#fad401"
            />
            <path
              id="Path_1406"
              data-name="Path 1406"
              d="M1288.5,886.636h-2.664l-.249.88h2.736Z"
              transform="translate(-1281.226 -863.663)"
              fill="#fad401"
            />
            <path
              id="Path_1407"
              data-name="Path 1407"
              d="M1301.536,884.928l.1.88h2.619l-.17-.88Z"
              transform="translate(-1282.452 -863.531)"
              fill="#fad401"
            />
            <path
              id="Path_1408"
              data-name="Path 1408"
              d="M1310.517,889.226l-.284-.88h-2.78l.218.884Z"
              transform="translate(-1282.907 -863.794)"
              fill="#fad401"
            />
            <path
              id="Path_1409"
              data-name="Path 1409"
              d="M1307.031,886.636l.218.88h2.73l-.285-.88Z"
              transform="translate(-1282.875 -863.663)"
              fill="#fad401"
            />
            <path
              id="Path_1410"
              data-name="Path 1410"
              d="M1306.611,884.928l.218.88h2.616l-.285-.88Z"
              transform="translate(-1282.842 -863.531)"
              fill="#fad401"
            />
            <path
              id="Path_1411"
              data-name="Path 1411"
              d="M1301.74,886.636l.1.88h2.727l-.166-.88Z"
              transform="translate(-1282.468 -863.663)"
              fill="#fad401"
            />
            <path
              id="Path_1412"
              data-name="Path 1412"
              d="M1296.4,889.231h2.846l-.048-.88h-2.786Z"
              transform="translate(-1282.058 -863.794)"
              fill="#fad401"
            />
            <path
              id="Path_1413"
              data-name="Path 1413"
              d="M1304.881,889.226l-.165-.88h-2.779l.1.884Z"
              transform="translate(-1282.483 -863.794)"
              fill="#fad401"
            />
            <path
              id="Path_1414"
              data-name="Path 1414"
              d="M1311.968,889.83l-2.663-6.711a1.214,1.214,0,0,0-1.122-.771h-4.494l-1.605,1.359h5.9l2.428,6.117h-27.914l1.943-6.115h6.025l-1.5-1.359h-4.734a1.211,1.211,0,0,0-1.165.861l-2.136,6.675a1.209,1.209,0,0,0,1.146,1.612h28.761a1.231,1.231,0,0,0,.461-.089A1.214,1.214,0,0,0,1311.968,889.83Z"
              transform="translate(-1280.863 -863.333)"
              fill="#fad401"
            />
          </g>
          <line
            id="Line_187"
            data-name="Line 187"
            y2="32.819"
            transform={`translate(70.423 ${
              productionOffset > 0 ? 0 : productionOffset < 0 ? 26 : 20
            }) rotate(${productionLineAngle})`}
            fill="none"
            stroke="#ffb600"
            strokeWidth="2"
          />
        </svg>
      );
    };

    const consumptionLegend = (props) => {
      const height = 20.5488;

      let consumptionOffset = 0;
      let consumptionLineAngle = 90;

      const firstProduction = this.state.data[0].production;
      const firstConsumption = this.state.data[0].consumption;

      if (firstConsumption > firstProduction) {
        if (
          firstConsumption - firstProduction <
            this.state.smallDifferenceThreshold &&
          firstConsumption - firstProduction > 0
        ) {
          consumptionOffset = -6;
          consumptionLineAngle = 110;
        }
      } else if (
        firstProduction - firstConsumption <
        this.state.smallDifferenceThreshold
      ) {
        consumptionOffset = 11;
        consumptionLineAngle = 70;
      }

      return (
        <svg
          x={props.cx - 44}
          y={props.cy - height / 2 + consumptionOffset}
          xmlns="http://www.w3.org/2000/svg"
          width="44.3658"
          height={height}
          viewBox="0 0 73.943 24.248"
        >
          <path
            id="Path_5511"
            data-name="Path 5511"
            d="M1265.631,874.451l15.746,13.127-16.405-21.057-3.808,8.749-14.134-11.94,14.94,19.154Z"
            transform="translate(-1247.03 -863.33)"
            fill="#ff1800"
          />
          <line
            id="Line_186"
            y2="32.819"
            transform={`translate(73.943 ${
              consumptionOffset > 0 ? -5 : consumptionOffset < 0 ? 23 : 13.979
            }) rotate(${consumptionLineAngle})`}
            fill="none"
            stroke="#ff1700"
            strokeWidth="2"
          />
        </svg>
      );
    };

    return this.state.data.length ? (
      <div
        style={{
          maxWidth: isNotAuto ? "1085px" : "1115px",
          marginLeft: isNotAuto ? "-40px" : "-90px",
        }}
      >
        <AreaChart
          width={isNotAuto ? 1072 : 1165}
          height={200}
          data={this.state.data}
          margin={{
            left: 45,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="consumptionGrad" x1={0} y1={0} x2={0} y2={1}>
              <stop offset="0%" stopColor="#FF0A00" stopOpacity={1} />
              <stop offset="100%" stopColor="#FFD800" stopOpacity={1} />
            </linearGradient>

            <linearGradient id="productionGrad" x1={0} y1={0} x2={0} y2={1}>
              <stop offset="0%" stopColor="#FFFF00" stopOpacity={1} />
              <stop offset="40%" stopColor="#FFD800" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#FFFF00" stopOpacity={0.57} />
            </linearGradient>
          </defs>

          <ReferenceDot
            x={0}
            y={this.state.data[0].consumption}
            stackId="0"
            shape={consumptionLegend}
          />
          <ReferenceDot
            x={0}
            y={this.state.data[0].production}
            stackId="1"
            shape={productionLegend}
          />

          <Area
            type="monotone"
            dataKey="consumption"
            stackId="0"
            stroke="rgba(0,0,0,0)"
            fill="url(#consumptionGrad)"
          />

          <Area
            type="monotone"
            dataKey="production"
            stackId="1"
            stroke="rgba(0,0,0,0)"
            fill="url(#productionGrad)"
          />
          {[...Array(12)].map((e, i) => (
            <ReferenceLine
              key={"referenceLine-" + i}
              x={i}
              stroke="rgba(56,56,56,0.2)"
              strokeWidth={1}
              alwaysShow={true}
            />
          ))}
        </AreaChart>
        <div
          style={{
            width: isNotAuto ? "1085px" : "1175px",
            height: isNotAuto ? "12px" : "13px",
            backgroundImage: `url(${GraphMask})`,
            backgroundSize: "93.33px",
            position: "relative",
            top: "-12px",
            marginLeft: "45px",
            zIndex: 5555,
          }}
        />

        <SunobiAxis
          style={{
            marginTop: "-17px",
            marginLeft: isNotAuto ? "-5px" : "45px",
            width: isNotAuto ? "104%" : "101%",
          }}
        >
          {[...this.state.months].map((m, i) => (
            <DateDiv key={`prodData-${i}`}>
              <Date>{m}</Date>
            </DateDiv>
          ))}
        </SunobiAxis>
      </div>
    ) : (
      <></>
    );
  }
}

export default ProductionGraph;
