import React from "react";
import tw, { styled } from "twin.macro";
import formatter from "../utils/formatter";

import savingsBackground from "../assets/versus-savings-background.png";

export default ({ graphData, fullSavingsTerm }) => {
  return (
    <SavingsBadge
      style={{
        left: "50%",
        backgroundImage: `url(${savingsBackground})`,
        backgroundSize: "325px 309px",
        width: "325px",
        height: "309px",
        marginLeft: "-155px",
        top: "200px",
      }}
    >
      <BadgeContainer style={{ width: "310px", height: "250px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SubTitle
            style={{
              color: "#224760",
              marginRight: "5px",
              fontSize: "2.75em",
            }}
          >
            {fullSavingsTerm}
          </SubTitle>
          <SubTitle style={{ color: "#224760" }}>
            Year
            <br />
            Savings
          </SubTitle>
        </div>
        <Title>
          ${" "}
          {graphData
            ? formatter(graphData.cumulativeSavings[fullSavingsTerm], 0)
            : ""}
        </Title>
      </BadgeContainer>
    </SavingsBadge>
  );
};

const SavingsBadge = tw.div`
    absolute
    
`;
const BadgeContainer = tw.div`
    flex
    flex-col
    justify-center
    items-center
`;

const SubTitle = tw.h1`
    text-lg uppercase font-normal tracking-wider leading-none mb-3
`;
const Title = styled.h1`
  ${tw`text-5xl uppercase font-normal tracking-wider text-white`}
  font-size: 2.8rem;
`;
