export default ({
  utilityEscalator,
  firstPayment,
  secondPayment,
  totalUtilityTopupCost,
  totalAnnualEnergyCost,
  fullSavingsTerm,
  loanTerm,
  packagePrice,
  isCash,
  taxCredit,
  initialTerm,
  isLease = false,
  leaseEscalator = 0
}) => {
  const appreciationFactor = 1 + utilityEscalator / 100;
  const startingMonthly = totalAnnualEnergyCost / 12;
  const startingUtility = totalUtilityTopupCost
    ? totalUtilityTopupCost / 12
    : 0;

  const before = [];
  const loanOnly = [];
  const after = [];

  // Array Definitions
  const cumulativeSavings = [isCash ? packagePrice * -1 : 0];
  const cumulativeCost = [isCash ? packagePrice * -1 : 0];
  const yearlyDifference = [isCash ? packagePrice : 0];
  const cumulativeUtility = [0];

  // Before
  before.push(startingMonthly);
  for (let i = 0; i < fullSavingsTerm; i++)
    before.push(startingMonthly * Math.pow(appreciationFactor, i + 1));

  loanOnly.push(isCash ? packagePrice / -12 : firstPayment);

  for (let i = 0; i < fullSavingsTerm; i++)
    if (isLease) {
      if (i < loanTerm) {
        loanOnly.push(loanOnly[i] * (1 + leaseEscalator))
      }
      else {
        loanOnly.push(loanOnly[i])
      }
    }
    else {
      loanOnly.push(
        loanTerm > i ? (i < initialTerm / 12 ? firstPayment : secondPayment) : 0
      );
    }

  // After is only used if there is a utility Top up
  if (startingUtility) {

    if (isLease) {
      loanOnly.forEach((loanPayment, i) => {
        after.push(loanPayment + startingUtility * Math.pow(appreciationFactor, i + 2))
      })
    }
    else {

      after.push(
        firstPayment +
        (startingUtility ? startingUtility * appreciationFactor : 0)
      );
      for (let i = 0; i < fullSavingsTerm; i++) {
        after.push(
          startingUtility * Math.pow(appreciationFactor, i + 2) +
          (loanTerm > i
            ? i < initialTerm / 12
              ? firstPayment
              : secondPayment
            : 0)
        );
      }
    }
  }

  // Accumulators
  let accumulativeSavings = isCash ? packagePrice * -1 : 0;
  let accumulativeCost = 0;

  for (let i = 1; i < before.length; i++) {
    const currentSaving =
      accumulativeSavings +
      before[i] * 12 -
      (startingUtility ? after[i] : loanOnly[i]) * 12;

    const currentCost = accumulativeCost + before[i] * 12;
    cumulativeSavings.push(
      isCash && i == 2 ? currentSaving + taxCredit : currentSaving
    );
    cumulativeCost.push(currentCost);
    yearlyDifference.push(
      before[i] * 12 - (startingUtility ? after[i] : loanOnly[i]) * 12
    );
    // Set for next loop
    accumulativeSavings =
      isCash && i == 2 ? currentSaving + taxCredit : currentSaving;
    accumulativeCost = currentCost;
  }

  // Calculate cumulative utility cost
  if (startingUtility) {
    let accumulativeUtility = 0;
    for (let i = 1; i < before.length; i++) {
      const currentUtility =
        accumulativeUtility + (after[i] - loanOnly[i]) * 12;
      cumulativeUtility.push(currentUtility);
      accumulativeUtility = currentUtility;
    }
  }

  return {
    before,
    after,
    cumulativeSavings,
    cumulativeCost,
    cumulativeUtility,
    yearlyDifference,
    loanOnly,
  };
};
